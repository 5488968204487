import React, { useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { UploadCloud } from 'react-feather';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { debounce } from 'lodash';
import TbCheckbox from '../../../../components/common/ui/form/TbCheckbox';
import { useForm } from 'react-hook-form';

interface DefaultProps {
  onComplete: (logos: CovertLogo[]) => void;
}
export interface ConvertLogoColor {
  url?: string;
  xml?: string;
  postfixName: string;
}
export interface ConvertLogoFormat {
  origin?: ConvertLogoColor;
  white?: ConvertLogoColor;
  black?: ConvertLogoColor;
  folderName: string;
  width?: number;
  height?: number;
}
export interface CovertLogo {
  id: number;
  name: string;
  svg: ConvertLogoFormat;
  png: ConvertLogoFormat | null;
  jpg: ConvertLogoFormat | null;
}

const ConvertFileSelector = ({ onComplete }: DefaultProps) => {
  const refFileInput = useRef<HTMLInputElement>(null);
  const [isTransparent, setIsTransparent] = useState<boolean>(true);
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [previewLogs, setPreviewLogs] = useState<CovertLogo[]>([]);
  const { register } = useForm();

  /**
   * SVG Fill 속성 순회 컬러 변경.
   * */
  const updateEachFill = (elements: NodeListOf<SVGElement>, color: string) => {
    elements.forEach((el) => {
      el.setAttribute('fill', color);
      el.style.fill = color;
    });
  };

  /**
   * SVG Fill 컬러 변경.
   * */
  const updateFill = (strSvgXml: string, color: string) => {
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(strSvgXml, 'image/svg+xml');

    updateEachFill(svgDoc.querySelectorAll('path'), color);
    updateEachFill(svgDoc.querySelectorAll('rect'), color);
    updateEachFill(svgDoc.querySelectorAll('polygon'), color);
    updateEachFill(svgDoc.querySelectorAll('ellipse'), color);
    updateEachFill(svgDoc.querySelectorAll('circle'), color);
    updateEachFill(svgDoc.querySelectorAll('line'), color);
    updateEachFill(svgDoc.querySelectorAll('polyline'), color);

    // fill 속성이 변경된 xml 코드
    return new XMLSerializer().serializeToString(svgDoc);
  };

  /**
   * 파일 변경
   * */
  const handleOnChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const files = Array.from(e.target.files || []);
    const resultArr: CovertLogo[] = [];

    await Promise.all(
      files.map((file, index) => {
        if (file && file.type === 'image/svg+xml') {
          return new Promise<void>((resolve) => {
            const reader = new FileReader();
            reader.onload = async (event) => {
              const svgXml = await fetch(event.target?.result as string).then((response) => response.text());
              resultArr.push({
                id: index + 1,
                name: `type${index + 1}`,
                svg: {
                  origin: {
                    xml: svgXml,
                    postfixName: '_Origin.svg',
                  },
                  white: {
                    xml: updateFill(svgXml, '#ffffff'),
                    postfixName: '_White.svg',
                  },
                  black: {
                    xml: updateFill(svgXml, '#000000'),
                    postfixName: '_Black.svg',
                  },
                  folderName: 'SVG',
                },
                png: {
                  origin: {
                    postfixName: '_Origin.svg',
                  },
                  white: {
                    postfixName: '_White.svg',
                  },
                  black: {
                    postfixName: '_Black.svg',
                  },
                  folderName: 'PNG',
                },
                jpg: {
                  origin: {
                    postfixName: '_Origin.svg',
                  },
                  white: {
                    postfixName: '_White.svg',
                  },
                  black: {
                    postfixName: '_Black.svg',
                  },
                  folderName: 'JPG',
                },
              });
              resolve();
            };
            reader.readAsDataURL(file);
          });
        }
      }),
    );

    setPreviewLogs(resultArr);
    onComplete(resultArr);

    // 인풋 리셋
    if (refFileInput.current) {
      (refFileInput.current as HTMLInputElement).value = '';
    }
  };

  /**
   * 드래그 오버
   * */
  const handelOnDragover = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  /**
   * 드래그 리브
   * */
  const handelOnDragleave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  /**
   * `onComplete` 함수만 디바운스하는 `debouncedOnComplete`를 생성
   * */
  const debouncedOnComplete = useCallback(
    debounce((updatedLogs: any[]) => {
      onComplete(updatedLogs);
    }, 700),
    [onComplete],
  );

  return (
    <Card>
      <CardHeader>
        <h5>SVG로고 선택</h5>
        <div className={'d-flex'}>
          <TbCheckbox
            label={'투명 배경'}
            name={'transparent'}
            type={'checkbox'}
            checked={isTransparent}
            register={register}
            formGroupStyle={{ marginBottom: '0 !important' }}
            onChange={(e) => {
              console.log('check ;', e);
              setIsTransparent(e.target.checked);
            }}
          />
          {previewLogs.length > 0 && (
            <Button className={'m-l-10'} color={'primary'} onClick={() => setPreviewLogs([])}>
              리셋
            </Button>
          )}
        </div>
      </CardHeader>
      <CardBody>
        {previewLogs.length > 0 ? (
          <div>
            {previewLogs.map((logo, originIndex) => (
              <Row key={logo.id} className={'mb-4'}>
                <Col sm={12}>
                  <div className={'d-flex align-items-center m-b-5'}>
                    <div className={'flex-shrink-0 p-r-5 f-w-600'}>로고 이름 : </div>
                    <input
                      className={'form-control'}
                      style={{ maxWidth: 250 }}
                      value={logo.name}
                      onChange={(e) => {
                        const newPreviewLogs = previewLogs.map((logo, index) => {
                          return originIndex === index ? { ...logo, name: e.target.value } : logo;
                        });
                        setPreviewLogs(newPreviewLogs);
                        debouncedOnComplete(newPreviewLogs);
                      }}
                    />
                  </div>
                </Col>
                <Col sm={4}>
                  <ConvertLogoWrap
                    isTransparent={isTransparent}
                    dangerouslySetInnerHTML={{ __html: logo.svg.origin?.xml || '' }}
                  ></ConvertLogoWrap>
                </Col>
                <Col sm={4}>
                  <ConvertLogoWrap
                    style={{ backgroundColor: '#000' }}
                    isTransparent={isTransparent}
                    dangerouslySetInnerHTML={{ __html: logo.svg.white?.xml || '' }}
                  ></ConvertLogoWrap>
                </Col>
                <Col sm={4}>
                  <ConvertLogoWrap
                    isTransparent={isTransparent}
                    dangerouslySetInnerHTML={{ __html: logo.svg.black?.xml || '' }}
                  ></ConvertLogoWrap>
                </Col>
              </Row>
            ))}
          </div>
        ) : (
          <Container
            onDragOver={(e) => handelOnDragover(e)}
            onDragLeave={(e) => handelOnDragleave(e)}
            style={{ borderColor: isDragOver ? '#0d6efd' : '#ccc' }}
          >
            <input
              ref={refFileInput}
              onChange={(e) => handleOnChange(e)}
              multiple={true}
              type="file"
              id="formFile"
              name="file"
              accept="image/svg+xml"
            />
            <div>
              <div className={'text-center'}>
                <UploadCloud size={40} color={'#007bff'} />
                <h5 className={'mb-0'}>
                  Open Finder <small>or</small> Drag & Drop
                </h5>
                <div className="text-danger">SVG 이미지만 선택 가능.</div>
              </div>
            </div>
          </Container>
        )}
      </CardBody>
    </Card>
  );
};
export default React.memo(ConvertFileSelector);

const Container = styled.div`
  background-color: #f6f6f6;
  position: relative;
  height: 150px;
  border: 3px dashed #ddd;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  > input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(10);
    transform-origin: top left;
    opacity: 0;
  }
`;

export const ConvertLogoWrap = styled.div<{ isTransparent?: boolean }>`
  display: flex;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 20px 20px;

  ${(props) =>
    props.isTransparent &&
    css`
      /* Use conic-gradient*/
      background: conic-gradient(#fff 25%, #ccc 25% 50%, #fff 50% 75%, #ccc 75%);
      background-size: 15px 15px;
    `}
`;
