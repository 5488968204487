import React, { CSSProperties, useState } from 'react';
import styled from 'styled-components';
import { DefaultInputStyle } from './WFColInputText';

interface PropsType {
  menu: { label: string; value: any }[];
  value: string | number;
  onChange: (newValue: any) => void;
  optionStyle?: CSSProperties;
}
const WFColInputSelect = ({ menu, value, onChange, optionStyle }: PropsType) => {
  const [inputValue, setInputValue] = useState<string | number>(value || '');
  return (
    <Select
      value={inputValue}
      onChange={(e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        onChange(newValue);
      }}
      style={{ ...optionStyle }}
    >
      <option value={''} disabled>
        --
      </option>
      {menu?.map((item: any) => (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </Select>
  );
};

export default React.memo(WFColInputSelect);

const Select = styled.select`
  ${DefaultInputStyle};
  border-radius: 10px;
  height: 17px;
`;
