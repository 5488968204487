/**
 * 확장자 확인.
 * */
export function checkFileExtension(fileName: string, acceptExtensions: string[]): boolean {
  const extension = fileName.split('.').pop()?.toLowerCase();
  const allowedExtensions = acceptExtensions.map((extension) => {
    return extension.replace('.', '').trim();
  });
  return extension ? allowedExtensions.includes(extension) : false;
}

/**
 * 파일 사이즈 변환.(바이트-> KB' | 'MB' | 'GB')
 * */
type Unit = 'B' | 'KB' | 'MB' | 'GB';
export const convertToUnit = (bytes: number, unit: Unit): number => {
  const sizeInBytes = bytes;

  switch (unit) {
    case 'KB':
      return sizeInBytes / 1024;
    case 'MB':
      return sizeInBytes / (1024 * 1024);
    case 'GB':
      return sizeInBytes / (1024 * 1024 * 1024);
    default:
      // 'B' - 바이트 단위
      return sizeInBytes;
  }
};

/**
 * 파일 사이즈 변환.(KB' | 'MB' | 'GB' -> 바이트)
 * */
export const convertToBytes = (size: number, unit: Unit): number => {
  switch (unit) {
    case 'KB':
      return size * 1024;
    case 'MB':
      return size * 1024 * 1024;
    case 'GB':
      return size * 1024 * 1024 * 1024;
    default:
      // 'B' - 바이트
      return size;
  }
};
