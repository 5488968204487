export const rowsStyle = {
  style: {
    style: {
      minHeight: '24px !important',
    },
  },
};
export const headCellsStyle = {
  style: {
    background: '#000',
    color: '#fff',
    padding: '4px 4px',
    borderRight: '1px solid #ddd',
    '&:last-of-type': {
      borderRight: 'none', // 마지막 헤더 셀의 오른쪽 보더 제거
    },
  },
};
export const cellsStyle = {
  style: {
    padding: '4px 4px',
    borderRight: '1px solid #ddd',
    '&:last-of-type': {
      borderRight: 'none', // 마지막 헤더 셀의 오른쪽 보더 제거
    },
  },
};
