import APIClient from './APIClient';

export const getSetting = async () => {
  const response = await APIClient.get(`/v2/api/setting`);
  return response.data;
};
// Patch
export const patchSetting = async (data: any) => {
  const response = await APIClient.patch(`/v2/api/setting`, data);
  return response.data;
};
