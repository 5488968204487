import React from 'react';

// 카테고리
export const CategoryIndustryColumns = [
  {
    name: 'id',
    selector: (row: any) => row.id,
    // sortable: true,
    center: true,
    // wrap: true,
    width: '60px',
  },
  {
    name: '카테고리',
    selector: (row: any) => row.nameKoKr,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '활성 여부',
    selector: (row: any) => row.active,
    cell: (row: any) => {
      return row.active ? (
        <span className="btn btn-outline-success btn-xs">활성</span>
      ) : (
        <span className="btn btn-outline-warning btn-xs">비활성</span>
      );
    },
    sortable: true,
    center: true,
    wrap: true,
  },
];

// 산업군
export const IndustryColumns = [
  {
    name: 'id',
    selector: (row: any) => row.id,
    // sortable: true,
    center: true,
    // wrap: true,
    width: '60px',
  },
  {
    name: '카테고리',
    selector: (row: any) => row.industryCategory.nameKoKr,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '산업군',
    selector: (row: any) => row.nameKoKr,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '활성 여부',
    selector: (row: any) => row.active,
    cell: (row: any) => {
      return row.active ? (
        <span className="btn btn-outline-success btn-xs">활성</span>
      ) : (
        <span className="btn btn-outline-warning btn-xs">비활성</span>
      );
    },
    sortable: true,
    center: true,
    wrap: true,
  },
];
