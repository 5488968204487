import APIClient from './APIClient';
import { PageInfo } from './types';

// Get
export interface ReqWorkflowList extends PageInfo {
  type?: string;
  onBoarding?: string;
  design?: string;
  priority?: string;
  // idBrand?: string | undefined;
  process?: string;
  businessCard?: string;
  manager?: string;
  platform?: string;
  payMethod?: string;
}

// Create Update
export interface ReqWorkflow {
  id?: number;
  idBrand?: number;
  onBoarding?: string | undefined;
  design?: string;
  platform?: string;
  manager?: string;
  priority?: string;
  startDate?: string | Date | null;
  endDate?: string | Date | null;
  brandName?: string;
  businessName?: string;
  userName?: string;
  phoneNumber?: string;
  price?: string;
  memo?: string;
  refund?: string;
  type?: string;
  process?: string;
  businessCard?: string;
  payMethod?: string;
  request?: string;
  paid?: boolean;
  payment?: boolean;
  email?: string;
}
export interface ResWorkflow {
  onBoarding?: string;
  design?: string;
  platform?: string;
  manager?: string;
  priority?: string;
  startDate?: string;
  endDate?: string;
  brandName?: string;
  businessName?: string;
  userName?: string;
  phoneNumber?: string;
  price?: string;
  memo?: string;
  refund?: string;
  type: string;
  process: string;
  businessCard: string;
  payMethod: string;
  request: string;
  paid: boolean;
  payment: boolean;
  email: string;
}

// WorkFlow Page Info
export const getWorkflowPage = async (params: ReqWorkflowList) => {
  const response = await APIClient.get('/v2/api/workflow/page', {
    params,
  });
  return response.data;
};

// Create WorkFlow
export const createWorkflow = async (workflow: ReqWorkflow) => {
  const response = await APIClient.post('/v2/api/workflow', workflow);
  return response.data;
};

// Update WorkFlow
export const patchWorkflow = async (id: string, workflow: ReqWorkflow) => {
  const response = await APIClient.patch(`/v2/api/workflow/${id}`, workflow);
  return response.data;
};

// Get WorkFlow Enum
export const getWorkflowEnum = async () => {
  const response = await APIClient.get('/v2/api/workflow/enum');
  return response.data;
};

// Get WorkFlow Detail
export const getWorkflowDetail = async (id: string) => {
  const response = await APIClient.get(`/v2/api/workflow/${id}`);
  return response.data;
};

// Delete
export const deleteWorkflow = async (id: string) => {
  const response = await APIClient.delete(`/v2/api/workflow/${id}`);
  return response.data;
};

// 브랜드 연결
export const patchWorkflowConnect = async (ids: { idWorkflow: number; idBrand: number }) => {
  const response = await APIClient.patch('/v2/api/workflow/connect-brand', ids);
  return response.data;
};

export const getLogoQuestDetail = async (idBrand: number) => {
  const response = await APIClient.get(`/v2/api/quest/logo?idBrand=${idBrand}`);
  return response.data;
};
