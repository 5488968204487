import React, { Fragment, useState } from 'react';
import useApiError from '../../hooks/useApiError';
import { Link, useNavigate } from 'react-router-dom';
import { PageInfo } from '../../api/types';
import { useQuery } from 'react-query';
import { getEditorElementCollectionPage } from '../../api/editorElementAPI';
import { formatEndDate, formatStartDate } from '../../utils/formatUtils';
import Breadcrumb from '../../components/common/breadcrumb';
import TbFilter from '../../components/common/ui/TbFilter';
import DataTable from 'react-data-table-component';
import { ElementCollectionColumns } from './data/element-columns';
import { cellsStyle, headCellsStyle, rowsStyle } from '../../data/customizer/react-data-table-config';
import { Col, Row } from 'reactstrap';

const EditorElementCollectionList = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();
  const [pageInfoData, setPageInfoData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  //  state
  const [reqParam, setReqParam] = useState<PageInfo>({
    query: '',
    startDate: '',
    endDate: '',
    page: 0,
    size: 30,
    sort: '',
  });

  /**
   * 목록 조회.
   * */
  const { isLoading } = useQuery(
    ['getEditorElementCollectionPage', { reqParam }], // queryKey에 reqParam을 개별 요소로 전달
    () => getEditorElementCollectionPage(reqParam), // queryFn에 필요한 인수들을 각각 전달
    {
      onSuccess: (data) => {
        setPageInfoData(data._embedded.collections || []);
        setTotalRows(data?.page?.totalElements || 0);
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 페이지 변경 핸들러
   * */
  const handlePageChange = (page: number) => {
    setReqParam({
      ...reqParam,
      page: page - 1,
    });
  };

  /**
   * 한 페이지 당 보여줄 행 수 변경 핸들러
   * */
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParam({
      ...reqParam,
      size: newPerPage,
      page: page - 1,
    });
  };

  /**
   * 검색 핸들러
   * */
  const onSubmitSearch = (filters: any) => {
    setReqParam({
      ...reqParam,
      query: filters.query,
      startDate: filters.startDate ? formatStartDate(filters.startDate) : null,
      endDate: filters.endDate ? formatEndDate(filters.endDate) : null,
    });
  };

  /**
   * 행 클릭 핸들러
   * */
  const handleRowClicked = (row: any) => {
    navigate(`/admin/editor-element/collection/update/${row.id}`);
  };

  return (
    <Fragment>
      <Row>
        <Col sm={7}>
          <Breadcrumb parent="에디터 요소 관리" title="컬렉션">
            <Link className="btn btn-primary" to={`/admin/editor-element/collection/create`}>
              새로 등록
            </Link>
          </Breadcrumb>
          <div className="container-fluid">
            {/* :::::::::: Filter : Start ::::::::::  */}
            <TbFilter onSubmit={onSubmitSearch} />
            {/* :::::::::: Filter : End ::::::::::  */}

            {/*  :::::::::: List ::::::::::  */}
            <DataTable
              noHeader
              columns={ElementCollectionColumns}
              data={pageInfoData}
              progressPending={isLoading}
              highlightOnHover
              pointerOnHover
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={10}
              paginationRowsPerPageOptions={[5, 10, 30, 50, 100]}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              onRowClicked={handleRowClicked}
              dense={true}
              customStyles={{
                rows: rowsStyle,
                headCells: headCellsStyle,
                cells: cellsStyle,
              }}
            />
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default React.memo(EditorElementCollectionList);
