import React from 'react';
import { Col, Row } from 'reactstrap';

// props로 logoQuestInfo 받아오기

interface PropsType {
  idBrand?: number;
  brandQuestData?: any;
}

const ManageLogoQuest: React.FC<PropsType> = ({ idBrand, brandQuestData }: PropsType) => {
  // const handleApiError = useApiError(); // 에러 처리 훅
  // const { data: brandQuestData } = useQuery(
  //   ['brandQuestData', idBrand],
  //   () => getLogoQuestDetail(idBrand as number),
  //   {
  //     enabled: !!idBrand,
  //     onError: (error) => {
  //       handleApiError(error);
  //     },
  //   },
  // );
  return (
    <div className="card">
      <div className={'card-header'}>
        <h5>로고 퀘스트 정보</h5>
      </div>
      <div className={'card-body'}>
        {!idBrand && <p className={`text-center m-5-15 text-danger`}>브랜드를 연결하세요.</p>}
        {idBrand && !brandQuestData && <p className={`text-center m-5-15 text-danger`}>로고 퀘스트를 진행해주세요.</p>}
        {brandQuestData && (
          <Row className="details">
            <Col xs="6" className={'detail-item'}>
              담당자 성함
              <br />
              <span>{brandQuestData.userName ? brandQuestData.userName : '-'}</span>
            </Col>
            <Col xs="6" className={'detail-item'}>
              기업명 / 브랜드명
              <br />
              <span>{brandQuestData.name}</span>
            </Col>
            <Col xs="6" className={'detail-item'}>
              이메일
              <br />
              <span>{brandQuestData.email ? brandQuestData.email : '-'}</span>
            </Col>
            <Col xs="6" className={'detail-item'}>
              핸드폰 번호
              <br />
              <span>{brandQuestData.phoneNumber ? brandQuestData.phoneNumber : '-'}</span>
            </Col>
            <Col xs="6" className={'detail-item'}>
              심볼
              <br />
              <span>{brandQuestData.symbol ? brandQuestData.symbol : '-'}</span>
            </Col>
            <Col xs="6" className={'detail-item'}>
              유입경로
              <br />
              <span>{brandQuestData.funnel ? brandQuestData.funnel : '-'}</span>
            </Col>

            <Col xs="6" className={'detail-item'}>
              메인 컬러
              <br />
              <span>{brandQuestData.logoColor}</span>
            </Col>
            <Col xs="6" className={'detail-item'}>
              로고 스타일
              <br />
              <span>{brandQuestData.logoStyle}</span>
            </Col>

            <Row>
              <Col xs="12" className={'detail-item'}>
                서비스 설명
                <br />
                <span>{brandQuestData.service ? brandQuestData.service : '-'}</span>
              </Col>
            </Row>
            {/*<Col xs="12" className={'detail-item'}>*/}
            {/*  첨부 파일*/}
            {/*  <br />*/}
            {/*  {!brandQuestData.imageUrl1 && !brandQuestData.imageUrl2 && !brandQuestData.imageUrl3 && '-'}*/}
            {/*  {['imageUrl1', 'imageUrl2', 'imageUrl3'].map((key, index) =>*/}
            {/*    brandQuestData?.[key] ? (*/}
            {/*      <div key={key}>*/}
            {/*        <a href={brandQuestData[key]} target="_blank" rel="noreferrer">*/}
            {/*          첨부 파일 {index + 1}*/}
            {/*        </a>*/}
            {/*      </div>*/}
            {/*    ) : null,*/}
            {/*  )}*/}
            {/*</Col>*/}
          </Row>
        )}
      </div>
    </div>
  );
};

export default React.memo(ManageLogoQuest);
