import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { useMutation, useQuery } from 'react-query';
import useApiError from '../../../hooks/useApiError';
import { createPayLink, deletePayLink, getPayLink } from '../../../api/subscriptionAPI';
import { getPlanList, ReqPlanList } from '../../../api/planAPI';
import { getCreditPlanList, ReqCreditPlanList } from '../../../api/creditPlanAPI';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import moment from 'moment';

interface ModalProps {
  idSubscription: number;
  status: string;
  toggle?: () => void;
  isOpen: boolean;
}

function PayLinkModal({ idSubscription, status, toggle, isOpen }: ModalProps) {
  const handleApiError = useApiError(); // 에러 처리 훅

  const [listPlan, setListPlan] = useState([]);
  const [listCreditPlan, setListCreditPlan] = useState([]);
  const [payLink, setPayLink] = useState<any>();
  const [reqPlanParams] = useState<ReqPlanList>();
  const [reqCreditPlanParams] = useState<ReqCreditPlanList>();
  const deletePayLinkMutation = useMutation(deletePayLink);
  const postPayLinkMutation = useMutation(createPayLink);
  const [planPrice, setPlanPrice] = useState<number>(0);
  const [creditPlanPrice, setCreditPlanPrice] = useState<number>(0);
  const [selectedPlan, setSelectedPlan] = useState<string>();
  const [selectedCreditPlan, setSelectedCreditPlan] = useState<string>();
  const [endDate, setEndDate] = useState<Date | null>();
  const [periodType, setPeriodType] = useState<string>('MONTHLY');

  const { refetch } = useQuery(['getPayLink', idSubscription], () => getPayLink(String(idSubscription)), {
    enabled: status === 'EXPIRED', // 조건이 맞을 때만 쿼리를 실행
    onSuccess: (data) => {
      setPayLink(data || null);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  useEffect(() => {
    if (payLink?.periodType === 'MONTHLY') {
      setPlanPrice(payLink?.relativePlan?.price);
      if (payLink?.relativeCreditPlan) {
        setCreditPlanPrice(payLink?.relativeCreditPlan?.price);
      }
    } else if (payLink?.periodType === 'YEARLY') {
      if (10 < payLink?.relativePlan?.level && payLink?.relativePlan?.level < 20) {
        setPlanPrice(payLink?.relativePlan?.price * 0.9);
      } else {
        setPlanPrice(payLink?.relativePlan?.price * 12 * 0.9);
      }
      if (payLink?.relativeCreditPlan) {
        setCreditPlanPrice(payLink?.relativeCreditPlan?.price * 12 * 0.9);
      }
    }
  }, [payLink]);

  useQuery(['getPlanList', reqPlanParams], () => getPlanList({ isActive: true }), {
    onSuccess: (data) => {
      setListPlan(data || []);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  useQuery(['getCreditPlanList', reqCreditPlanParams], () => getCreditPlanList({ type: 'SUBSCRIBE', active: true }), {
    onSuccess: (data) => {
      setListCreditPlan(data || []);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 크레딧 패치 성공시 핸들러
   * */

  const handleCopyLink = () => {
    const copyText = `https://tidy-b.com/payment/${payLink?.id}`;

    // 클립보드에 복사
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        toast.success(`링크가 복사되었습니다.`);
      })
      .catch((err) => {
        console.error('복사에 실패했습니다.', err);
      });
  };

  const deleteLink = async () => {
    if (payLink) {
      try {
        await deletePayLinkMutation.mutateAsync(payLink?.id);
        toast.success(`링크가 삭제되었습니다.`);
        refetch();
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  const createLink = async () => {
    try {
      await postPayLinkMutation.mutateAsync({
        idPlan: selectedPlan,
        idCreditPlan: selectedCreditPlan,
        idSubscription: String(idSubscription),
        expiredDate: endDate,
        periodType: periodType,
      });
      toast.success(`링크가 생성되었습니다.`);
      refetch();
    } catch (error) {
      handleApiError(error);
    }
  };

  const selectedPlanId = (id: string, price: number) => {
    setSelectedPlan(id);
    if (id != '2') {
      setSelectedCreditPlan(undefined);
      setCreditPlanPrice(0);
    }
    if (periodType === 'MONTHLY') {
      setPlanPrice(price);
    } else if (periodType === 'YEARLY') {
      setPlanPrice(price * 12 * 0.9);
    }
  };

  const selectedCreditPlanId = (id: string, price: number) => {
    setSelectedCreditPlan(id);
    if (periodType === 'MONTHLY') {
      setCreditPlanPrice(price);
    } else if (periodType === 'YEARLY') {
      setCreditPlanPrice(price * 12 * 0.9);
    }
  };

  const selectedPeriodType = (type: string) => {
    if (periodType === 'YEARLY' && type === 'MONTHLY') {
      setPlanPrice(((planPrice / 12) * 10) / 9);
      setCreditPlanPrice(((creditPlanPrice / 12) * 10) / 9);
    }
    if (periodType === 'MONTHLY' && type === 'YEARLY') {
      setPlanPrice(planPrice * 12 * 0.9);
      setCreditPlanPrice(creditPlanPrice * 12 * 0.9);
    }
    setPeriodType(type);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-body"
      centered={true}
      size={status === 'EXPIRED' && !payLink ? 'xl' : 'md'}
      fade={true}
    >
      {/* Header */}
      <ModalHeader className={'modal-header'} toggle={toggle}>
        결제 링크 조회
      </ModalHeader>
      {/*   Body  */}
      <Form className="theme-form">
        <ModalBody className="modal-body">
          {status === 'EXPIRED' &&
            (payLink ? (
              <div>
                <table className="table table-hover table-bordered">
                  <thead>
                    <tr className="bg-gray-lighter">
                      <th>구분</th>
                      <th>내용</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>요금제</th>
                      <td>
                        ID. {payLink?.relativePlan?.id}
                        <br />
                        Lv. {payLink?.relativePlan?.level}
                        <br />
                        {payLink?.relativePlan?.title}
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <th>크레딧 요금제</th>
                      {payLink?.relativeCreditPlan ? (
                        <td>
                          ID. {payLink?.relativeCreditPlan?.id}
                          <br />
                          {payLink?.relativeCreditPlan?.credit} credit
                          <br />
                          {payLink?.relativeCreditPlan?.title}
                          <br />
                        </td>
                      ) : (
                        <td> - </td>
                      )}
                    </tr>
                    <tr>
                      <th>결제 주기</th>
                      {payLink?.periodType === 'MONTHLY' && <td>월간</td>}
                      {payLink?.periodType === 'YEARLY' && <td>연간</td>}
                    </tr>
                    <tr>
                      <th>결제 금액</th>
                      <td>
                        <div>
                          <span>기본료</span>
                          <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {planPrice.toLocaleString()} 원
                          </span>
                        </div>
                        <div>
                          <span>크레딧 구독</span>
                          <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {creditPlanPrice.toLocaleString()} 원
                          </span>
                        </div>
                        <hr />
                        <div>
                          <span>총 결제 금액</span>
                          <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {(planPrice + creditPlanPrice).toLocaleString()} 원
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>링크 만료일</th>
                      <td>{payLink?.expiredDate ? moment(payLink?.expiredDate).format('YYYY/MM/DD') : ''}</td>
                    </tr>
                    <tr>
                      <th>링크</th>
                      <td>
                        <div className="input-group m-b">
                          <div className="input-group-btn">
                            <button
                              id="btnLinkCopy"
                              type="button"
                              className="btn btn-default"
                              style={{ marginRight: '10px', backgroundColor: '#d3d3d3' }}
                              onClick={handleCopyLink}
                            >
                              링크복사
                            </button>
                          </div>
                          <input
                            id="inputLink"
                            type="text"
                            value={`https://tidy-b.com/payment/${payLink?.id}`}
                            className="form-control"
                            readOnly
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <div>
                <Row>
                  <Col>
                    <h5>약정 타입</h5>
                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        name="periodType"
                        value="MONTHLY"
                        checked={periodType === 'MONTHLY'}
                        onChange={() => selectedPeriodType('MONTHLY')}
                        style={{ marginRight: 10 }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox1"
                        style={{ fontWeight: 'bold', fontSize: '15px' }}
                      >
                        월간
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        name="periodType"
                        value="YEARLY"
                        checked={periodType === 'YEARLY'}
                        onChange={() => selectedPeriodType('YEARLY')}
                        style={{ marginRight: 10 }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox2"
                        style={{ fontWeight: 'bold', fontSize: '15px' }}
                      >
                        연간
                      </label>
                    </div>
                  </Col>
                  <Col>
                    <h5>링크 만료일</h5>
                    <DatePicker
                      className="datepicker-here form-control"
                      selected={endDate}
                      onChange={(date: Date | null) => {
                        date?.setHours(date?.getHours() + 9);
                        setEndDate(date);
                      }}
                      dateFormat={'yyyy-MM-dd'}
                      minDate={new Date()}
                    />
                  </Col>
                </Row>
                <br />
                <h5>구독 요금제 선택</h5>
                <hr />
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'center' }}>요금제명</th>
                      <th style={{ textAlign: 'center' }}>설명</th>
                      <th style={{ textAlign: 'center' }}>연납 할인율</th>
                      <th style={{ textAlign: 'right' }}>구독료/월</th>
                      <th style={{ textAlign: 'right' }}>구독료/연</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listPlan &&
                      listPlan.map((plan: any) => (
                        <tr key={plan.id}>
                          <td>
                            <input
                              type="radio"
                              value={plan.id}
                              checked={selectedPlan === plan.id}
                              onChange={() => selectedPlanId(plan.id, plan.price)}
                              style={{ marginRight: 10 }}
                            />
                            {plan.title} (Lv.{plan.level})
                          </td>
                          <td style={{ textAlign: 'center' }}>{plan.description}</td>
                          <td style={{ textAlign: 'center' }}>{plan.discountRate * 100}%</td>
                          <td style={{ textAlign: 'right' }}>{plan.price.toLocaleString()} 원</td>
                          {plan.level < 20 && 10 < plan.level ? (
                            <td style={{ textAlign: 'right' }}>
                              {(plan.price * (1 - plan.discountRate)).toLocaleString()} 원(월납)
                            </td>
                          ) : (
                            <td style={{ textAlign: 'right' }}>
                              {(plan.price * 12 * (1 - plan.discountRate)).toLocaleString()} 원(연납)
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
                <br />
                <h5>크레딧 요금제 선택</h5>
                <hr />
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'center' }}>크레딧</th>
                      <th style={{ textAlign: 'center' }}>할인율</th>
                      <th style={{ textAlign: 'right' }}>구독료/월</th>
                      <th style={{ textAlign: 'right' }}>구독료/연</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listCreditPlan &&
                      listCreditPlan.map((plan: any) => (
                        <tr key={plan.id}>
                          <td>
                            <input
                              type="radio"
                              value={plan.id}
                              checked={selectedCreditPlan === plan.id}
                              onChange={() => selectedCreditPlanId(plan.id, plan.price)}
                              style={{ marginRight: 10 }}
                              disabled={selectedPlan != '2'}
                            />
                            {plan.credit} credit
                          </td>
                          <td style={{ textAlign: 'center' }}>{plan.discountRate * 100}%</td>
                          <td style={{ textAlign: 'right' }}>{plan.price.toLocaleString()} 원</td>
                          <td style={{ textAlign: 'right' }}>{(plan.price * 12 * 0.9).toLocaleString()} 원</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div className="row" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <div className="col-md-6">
                    <hr />
                    <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>결제 주기</span>
                      <span>{periodType}</span>
                    </p>
                    <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>기본료</span>
                      <span>{planPrice.toLocaleString()} 원</span>
                    </p>
                    <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>크레딧 구독</span> <span>{creditPlanPrice.toLocaleString()} 원</span>
                    </p>
                    <h3 style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>총 결제 금액</span>
                      <span>{(planPrice + creditPlanPrice).toLocaleString()} 원</span>
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          {status === 'SUBSCRIBE' && (
            <div className={'card-body'}>
              <p className={`text-center m-5-15 text-danger`}>구독중인 상태에서는 결제링크 생성이 불가합니다.</p>
            </div>
          )}
        </ModalBody>
        {/* Footer */}
        <ModalFooter className={'modal-footer'}>
          {payLink && (
            <Button onClick={deleteLink} color="danger">
              삭제
            </Button>
          )}
          {!payLink && status === 'EXPIRED' && (
            <Button onClick={createLink} color="success">
              생성
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default React.memo(PayLinkModal);
