import APIClient from './APIClient';
import { PageInfo } from './types';

export interface ReqCreditPlanList extends PageInfo {
  type?: 'SINGLE' | 'SUBSCRIBE';
  active?: boolean;
}

export const getCreditPlanList = async (params: ReqCreditPlanList) => {
  const response = await APIClient.get(`/v2/api/credit-plan/list`, {
    params,
  });
  return response.data;
};
