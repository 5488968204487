import APIClient from './APIClient';
import { PageInfo } from './types';

export interface ReqCreditPage extends PageInfo {
  status?: 'ACCUMULATE' | 'USE' | 'EXPIRE' | 'REFUND' | 'CANCEL';
  idBrand?: number;
}

export interface ReqCreditUpdate {
  idBrand: number;
  accumulated: boolean;
  expireDate: string | null;
  credit: number;
  description: string;
}

// 크레딧 리스트
export const getCreditPage = async (params: ReqCreditPage) => {
  const response = await APIClient.get(`/v2/api/credit/page`, {
    params,
  });
  return response.data;
};

// 크레딧 상세페이지
export const getCredit = async (id: string) => {
  const response = await APIClient.get(`/v2/api/credit/${id}`);
  return response.data;
};

// 크레딧 적립 / 차감
export const postCredit = async (data: ReqCreditUpdate) => {
  const response = await APIClient.post(`/v2/api/credit`, data);
  return response.data;
};

// 크레딧 사용 취소
export const cancelCredit = async (id: string) => {
  const response = await APIClient.post(`/v2/api/credit/cancel`, id); // Ensure id is passed in the body
  return response.data;
};

// 크레딧 환불
export const refundCredit = async (id: string) => {
  const response = await APIClient.post(`/v2/api/credit/refund`, id); // Ensure id is passed in the body
  return response.data;
};
