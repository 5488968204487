import React, { Fragment } from 'react';
import sad from '../../assets/images/other-images/sad.png';
import { Link } from 'react-router-dom';
import { BACK_TO_HOME_PAGE } from '../../constant';
const Error503 = () => {
  return (
    <Fragment>
      <div className="page-wrapper">
        {/* <!-- error-503 start--> */}
        <div className="error-wrapper">
          <div className="container">
            <img className="img-100" src={sad} alt="" />
            <div className="error-heading">
              <h2 className="headline font-secondary">{'503'}</h2>
            </div>
            <div className="col-md-8 offset-md-2">
              <p className="sub-content">
                <h5>{'연결하려는 페이지는 현재 사용할 수 없습니다.'}</h5>
                <h5>{'존재하지 않는 페이지로 이동되었기 때문일 수 있습니다.'}</h5>
              </p>
            </div>
            <div>
              <Link to={`/admin/wokflow/list`} className="btn btn-secondary-gradien">
                {' '}
                {BACK_TO_HOME_PAGE}
              </Link>
            </div>
          </div>
        </div>
        {/* <!-- error-503 end--> */}
      </div>
    </Fragment>
  );
};

export default Error503;
