import APIClient from './APIClient';
import { PageInfo } from './types';

// 키워드
// 리스트
export const getKeywordList = async (params: PageInfo) => {
  const response = await APIClient.get(`/v2/api/keyword/page`, {
    params,
  });
  return response.data;
};
// 상세
export const getKeyword = async (id: string) => {
  const response = await APIClient.get(`/v2/api/keyword/${id}`, {});
  return response.data;
};
// Crate
export const postKeywordList = async (data: any) => {
  const response = await APIClient.post(`/v2/api/keyword`, data);
  return response.data;
};
// Delete
export const deleteKeyword = async (id: string) => {
  const response = await APIClient.delete(`/v2/api/keyword/${id}`, {});
  return response.data;
};
// Patch
export const patchKeyword = async (id: string, data: any) => {
  const response = await APIClient.patch(`/v2/api/keyword/${id}`, data);
  return response.data;
};
