import React, { ReactElement } from 'react';
import TBCheckbox from './TbCheckbox';
import { Controller } from 'react-hook-form';
import { TBFormGroup, TBLabel } from '../../styledComponents';

interface RadioGroupProps {
  label: string;
  message?: string | ReactElement;
  name: string;
  optionLabel?: [string, string];
  horizontal?: boolean;
  register: any;
  control?: any;
  errors?: any;
  validation?: any;
}

const TbRadioBoolean: React.FC<RadioGroupProps> = ({
  label,
  message,
  name,
  optionLabel = ['활성', '비활성'],
  horizontal = false,
  control,
  register = undefined,
  errors,
  validation,
}) => {
  return (
    <TBFormGroup horizontal={horizontal.toString()}>
      <TBLabel horizontal={horizontal.toString()}>
        {label}
        {validation?.required && <span className={'text-danger'}>(필수)</span>}
      </TBLabel>
      <div className={'w-100'}>
        <Controller
          control={control}
          render={({ field }) => (
            <>
              <TBCheckbox
                label={optionLabel?.[0]}
                type={'radio'}
                name={name}
                value={true}
                checked={field.value?.toString() === 'true'}
                horizontal={true}
                register={register}
                validation={validation}
              />
              <TBCheckbox
                label={optionLabel?.[1]}
                type={'radio'}
                name={name}
                value={false}
                checked={field.value?.toString() === 'false'}
                horizontal={true}
                register={register}
                validation={validation}
              />
            </>
          )}
          name={name}
        />
        {errors && errors[name] && (
          <div className="text-danger mt-1 m-t-0">
            {errors[name]?.message ? errors[name].message : `${label}을/를 선택하세요.`}
          </div>
        )}
        {message && <div>{message}</div>}
      </div>
    </TBFormGroup>
  );
};

export default TbRadioBoolean;
