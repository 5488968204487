import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import useApiError from '../../../../hooks/useApiError';
import { getPaymentList, ReqPaymentList } from '../../../../api/paymentAPI';
import { useQuery } from 'react-query';
import { paymentColumns } from '../data/payment-columns';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import { cellsStyle, headCellsStyle, rowsStyle } from '../../../../data/customizer/react-data-table-config';

interface PropsType {
  refetchTrigger?: boolean;
}
const PayUrlList = ({ refetchTrigger }: PropsType) => {
  const navigate = useNavigate();
  const handleApiError = useApiError(); // 에러 처리 훅
  const [listData, setListData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [reqParams, setReqParams] = useState<ReqPaymentList>({
    page: 0,
    size: 5,
    sort: '',
    target: 'UNIVERSAL',
  });

  /**
   * 페이지 데이터 요청.
   * */
  const { isLoading, refetch } = useQuery(['getPaymentList', { reqParams }], () => getPaymentList(reqParams), {
    onSuccess: (data) => {
      setListData(data?._embedded?.payments || []);
      setTotalRows(data?.page?.totalElements | 0);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  useDidMountEffect(() => {
    refetch();
  }, [refetchTrigger]);

  /**
   * 페이지 변경 핸들러
   * */
  const handlePageChange = (page: number) => {
    setReqParams({
      ...reqParams,
      page: page - 1,
    });
  };

  /**
   * 페이지 사이즈 변경
   * */
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParams({
      ...reqParams,
      size: newPerPage,
      page: page - 1,
    });
  };

  /**
   * 아이템 클릭
   * */
  const handleRowClicked = (row: any) => {
    navigate(`/admin/payment/read/${row.id}`);
  };

  return (
    <div>
      <h6>결제 링크 주문서</h6>
      <DataTable
        noHeader
        columns={paymentColumns}
        data={listData}
        progressPending={isLoading}
        highlightOnHover
        pointerOnHover
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 10, 15]}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onRowClicked={handleRowClicked}
        dense={true}
        customStyles={{
          rows: rowsStyle,
          headCells: headCellsStyle,
          cells: cellsStyle,
        }}
      />
    </div>
  );
};
export default React.memo(PayUrlList);
