import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ModalState {
  isOpen: boolean;
  message: string;
  showHeader?: boolean;
  showFooter?: boolean;
  headerTitle?: string;
  buttOnClick?: () => void;
}

const initialState: ModalState = {
  isOpen: false,
  message: '',
  showHeader: false,
  showFooter: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal(
      state,
      action: PayloadAction<{
        message: string;
        headerTitle?: string;
        buttOnClick?: () => void;
        showHeader?: boolean;
        showFooter?: boolean;
      }>,
    ) {
      state.isOpen = true;
      state.message = action.payload.message;
      state.headerTitle = action.payload.headerTitle;
      state.buttOnClick = action.payload.buttOnClick;
      state.showHeader = action.payload.showHeader;
      state.showFooter = action.payload.showFooter;
    },
    closeModal(state) {
      state.isOpen = false;
      state.message = '';
      state.headerTitle = '';
      state.buttOnClick = undefined;
      state.showHeader = false;
      state.showFooter = false;
    },
    toggleModal(state) {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { openModal, closeModal, toggleModal } = modalSlice.actions;

export default modalSlice.reducer;
