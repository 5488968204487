import APIClient from './APIClient';
import { PageInfo } from './types';

export interface ReqBrandPage extends PageInfo {
  market: boolean;
}

export const getBrandPage = async (params: ReqBrandPage) => {
  const response = await APIClient.get('/v2/api/brand/page', {
    params,
  });
  return response.data;
};

// 브랜드 정보 조회
export const getBrandInfo = async (id: string) => {
  const response = await APIClient.get(`/v2/api/brand/${id}`);
  return response.data;
};
