import React from 'react';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { TBFormGroup, TBLabel } from '../../styledComponents';

interface TbSelectProps {
  label?: string;
  id?: string;
  name: string;
  readonly?: boolean;
  placeholder?: string;
  disabled?: boolean;
  horizontal?: boolean;
  onChange?: (date: Date | null) => void;
  // datePicker
  showTimeSelect?: boolean;
  timeIntervals?: number;
  dateFormat?: string;
  // useForm
  control?: any;
  validation?: any;
  errors?: any;
}
const TbDatePicker = ({
  label,
  id,
  name,
  readonly = false,
  placeholder,
  disabled = false,
  horizontal = false,
  onChange,
  // datePicker
  showTimeSelect = false,
  timeIntervals = 30,
  dateFormat = 'yyyy-MM-dd hh:mm:ss',
  // useForm
  control,
  validation,
  errors = {},
}: TbSelectProps) => {
  return (
    <TBFormGroup horizontal={horizontal.toString()}>
      {!label ? null : (
        <TBLabel horizontal={horizontal.toString()}>
          {label}
          {validation?.required && <span className={'text-danger'}>(필수)</span>}
        </TBLabel>
      )}
      <div className={'w-100'}>
        <Controller
          control={control}
          name={name}
          rules={validation}
          render={({ field }) => (
            <DatePicker
              id={id}
              readOnly={readonly}
              disabled={disabled}
              selected={field.value ? field.value : null}
              showTimeSelect={showTimeSelect}
              timeIntervals={timeIntervals}
              dateFormat={dateFormat}
              placeholderText={placeholder}
              className={`form-control ${errors?.[name] && 'is-invalid'} `}
              onChange={(date: Date | null) => {
                onChange?.(date);
              }}
            />
          )}
        />
        {errors && errors[name] && (
          <div className="text-danger mt-1">{errors[name].message || `${label}을/를 선택하세요.`}</div>
        )}
      </div>
    </TBFormGroup>
  );
};

export default React.memo(TbDatePicker);
