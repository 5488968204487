import { PageInfo } from './types';
import APIClient from './APIClient';

export interface ReqContactList extends PageInfo {
  status?: 'REQUEST' | 'PROCEEDING' | 'COMPLETED' | 'CONVERSION';
}
export interface UpdateReqContact {
  memo?: string;
  status?: 'REQUEST' | 'PROCEEDING' | 'COMPLETED' | 'CONVERSION';
}

export interface ResContact {
  id: number;
  company: string;
  name: string;
  email: string;
  phoneNumber: string;
  memo: string;
}

// 문의 페이징
export const getContactPage = async (params: ReqContactList) => {
  const response = await APIClient.get(`/v2/api/contact/page`, {
    params,
  });
  return response.data;
};

// 문의 조회
export const getContact = async (id: string) => {
  const response = await APIClient.get(`/v2/api/contact/${id}`);
  return response.data;
};

// 문의 메모 수정
export const updateContact = async (id: string, data: UpdateReqContact) => {
  await APIClient.patch(`/v2/api/contact/${id}`, data);
};

// 문의 삭제
export const deleteContact = async (id: string) => {
  await APIClient.delete(`/v2/api/contact/${id}`);
};
