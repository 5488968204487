import React from 'react';
import { Loader } from 'react-feather';
import styled, { keyframes } from 'styled-components';

interface TbLoadingProps {
  size?: number;
  color?: string;
  padding?: number;
}

const TbLoading: React.FC<TbLoadingProps> = ({ size = 20, color, padding }) => {
  return (
    <Container style={{ width: size, height: size, padding: padding }}>
      <IconSpin>
        <Loader size={size} color={color} />
      </IconSpin>
    </Container>
  );
};

export default React.memo(TbLoading);

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

// 회전 애니메이션 정의
const rotate360 = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const IconSpin = styled.span`
  display: inline-block;
  animation: ${rotate360} 2s linear infinite;
  line-height: 0;
`;
