import APIClient from './APIClient';

/** 플랜 정보 */
export interface ResPlan {
  description: string;
  discountPrice: number;
  discountPriceOfMember: number;
  discountRate: number;
  id: number;
  level: number;
  memberCount: number;
  price: number;
  priceOfMember: number;
  priceOfYear: number;
  storage: number;
  templateCount: number;
  title: string;
}
/** 플랜 목록 요청 파라미터 */
export interface ReqPlanList {
  isActive?: boolean | undefined;
}

/** 플랜 목록 요청.*/
export const getPlanList = async (params: ReqPlanList) => {
  const response = await APIClient.get(`/v2/api/plan/list`, {
    params,
  });
  return response.data;
};
