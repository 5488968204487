import React, { Fragment, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import TbInput from '../../components/common/ui/form/TbInput';
import TbSelect from '../../components/common/ui/form/TbSelect';
import TbTextarea from '../../components/common/ui/form/TbTextarea';
import {
  createWorkflow,
  deleteWorkflow,
  getWorkflowDetail,
  getWorkflowEnum,
  patchWorkflow,
  ReqWorkflow,
} from '../../api/workflowAPI';
import useApiError from '../../hooks/useApiError';
import Breadcrumb from '../../components/common/breadcrumb';
import TbLoading from '../../components/common/ui/TbLoading';
import TbModal from '../../components/common/ui/TbModal';
import TbRadioBoolean from '../../components/common/ui/form/TbRadioBoolean';
import TbDatePicker from '../../components/common/ui/form/TbDatePicker';
import moment from 'moment';
import { enumToSelect } from '../../utils/enumUtils';

interface WorkflowFormData {
  id?: string;
  idBrand?: string;
  // 고객 정보
  type?: string;
  brandName?: string;
  businessName?: string;
  userName?: string;
  phoneNumber?: string;
  request?: string;

  // 작업 관리
  platform?: string;
  manager?: string;
  process?: string;
  onBoarding?: string;
  design?: string;
  businessCard?: string;
  priority?: string;
  startDate?: Date | null;
  endDate?: Date | null;
  memo?: string;

  // 결제 정보
  payMethod?: string;
  price?: string;
  email?: string;
  paid?: string;
  payment?: string;
  refund?: string;
}

const CreateWorkflow: React.FC = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const createWorkflowMutation = useMutation(createWorkflow);
  const patchWorkflowMutation = useMutation(({ id, data }: { id: string; data: any }) => patchWorkflow(id, data));
  const deleteMutation = useMutation(deleteWorkflow);
  const { id: idWorkflow } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Form
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<WorkflowFormData>({
    defaultValues: {
      type: 'GENERAL',
    },
  });

  // 초기 데이터 로드
  const { data: defaultData } = useQuery(
    ['getWorkflowDetail', idWorkflow],
    () => getWorkflowDetail(idWorkflow as string),
    {
      enabled: !!idWorkflow,
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  // 타입 리스트 로든
  const { data: enumType } = useQuery(['enumData'], getWorkflowEnum, {
    select: (data) => ({
      typeList: enumToSelect(data.typeList),
      onBoardingList: enumToSelect(data.onBoardingList),
      designList: enumToSelect(data.designList),
      platformList: enumToSelect(data.platformList),
      managerList: enumToSelect(data.managerList),
      priorityList: enumToSelect(data.priorityList),
      processList: enumToSelect(data.processList),
      businessCardList: enumToSelect(data.businessCardList),
      payMethodList: enumToSelect(data.payMethodList),
    }),
    onError: (error) => {
      handleApiError(error);
    },
  });

  useEffect(() => {
    if (defaultData) {
      console.debug('::: 갱신', defaultData);
      // 고객 정보
      setValue('type', defaultData.type);
      setValue('brandName', defaultData.brandName);
      setValue('businessName', defaultData.businessName);
      setValue('userName', defaultData.userName);
      setValue('phoneNumber', defaultData.phoneNumber);
      setValue('request', defaultData.request);
      // 계약정보
      setValue('manager', defaultData.manager);
      setValue('platform', defaultData.platform);
      setValue('process', defaultData.process);
      setValue('onBoarding', defaultData.onBoarding);
      setValue('design', defaultData.design);
      setValue('businessCard', defaultData.businessCard);
      setValue('priority', defaultData.priority);
      setValue('startDate', defaultData.startDate ? moment(defaultData.startDate).toDate() : null);
      setValue('endDate', defaultData.endDate ? moment(defaultData.endDate).toDate() : null);
      setValue('memo', defaultData.memo);
      // 결제 정보
      setValue('payMethod', defaultData.payMethod);
      setValue('price', defaultData.price);
      setValue('email', defaultData.email);
      setValue('paid', defaultData.paid);
      setValue('payment', defaultData.payment);
      setValue('refund', defaultData.refund);
    }
  }, [defaultData]);

  // 최종 Form Submit
  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const reqBody = {
      ...data,
      process: data.process ? data.process : null,
      onBoarding: data.onBoarding ? data.onBoarding : null,
      design: data.design ? data.design : null,
      businessCard: data.businessCard ? data.businessCard : null,
      priority: data.priority ? data.priority : null,
      payMethod: data.payMethod ? data.payMethod : null,
      startDate: data.startDate ? data.startDate.toISOString() : null,
      endDate: data.startDate ? data.startDate.toISOString() : null,
    } as ReqWorkflow;
    if (idWorkflow && Number(idWorkflow) > 0) {
      //Update
      try {
        await patchWorkflowMutation.mutateAsync({
          id: String(idWorkflow),
          data: { ...reqBody },
        });
        navigate(`/admin/workflow/read/${idWorkflow}`);
      } catch (error) {
        handleApiError(error);
      }
    } else {
      // Create
      try {
        await createWorkflowMutation.mutateAsync({
          ...reqBody,
        });
        navigate(`/admin/workflow/list`);
      } catch (error) {
        handleApiError(error);
      }
    }

    setIsLoading(false);
  };

  // 삭제 핸들러
  const handleDelete = async () => {
    if (idWorkflow) {
      try {
        await deleteMutation.mutateAsync(idWorkflow);
        navigate(`/admin/workflow/list`);
        toggleModal();
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  const toggleModal = () => {
    setIsDeleteModal(!isDeleteModal);
  };
  // =================== 공통 항목 끝 ================== //

  return (
    <Fragment>
      <TbModal isOpen={isDeleteModal} onClickConfirm={handleDelete} toggle={toggleModal}>
        <h5 className="text-center m-0">삭제 하시겠습니까?</h5>
      </TbModal>
      <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
        <Breadcrumb parent="고객관리" title={idWorkflow ? '수정' : '생성'}>
          <div className="mb-0">
            {idWorkflow ? (
              <Button onClick={toggleModal} color="danger" className="me-3" type={'button'}>
                삭제
              </Button>
            ) : (
              ''
            )}
            <Button color="secondary" className="me-3" type="submit">
              {isLoading ? <TbLoading /> : idWorkflow ? '수정' : '생성'}
            </Button>
            <Link to={`/admin/workflow/list`}>
              <Button color="primary">목록</Button>
            </Link>
          </div>
        </Breadcrumb>
        <Container fluid={true}>
          <Row>
            <Col sm="4">
              <Card>
                <div className="card-header">
                  <h5>고객 정보</h5>
                </div>
                <CardBody>
                  <TbSelect
                    label="고객 유형"
                    name="type"
                    options={enumType?.typeList ? enumType.typeList : []}
                    horizontal={true}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbInput
                    label="브랜드 이름"
                    name={'brandName'}
                    type="text"
                    horizontal={true}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbInput
                    label="기업명"
                    name={'businessName'}
                    type="text"
                    horizontal={true}
                    register={register}
                    errors={errors}
                  />
                  <TbInput
                    label="담당자 이름"
                    name={'userName'}
                    type="text"
                    horizontal={true}
                    register={register}
                    errors={errors}
                  />
                  <TbInput
                    label="연락처"
                    name="phoneNumber"
                    type="text"
                    horizontal={true}
                    register={register}
                    errors={errors}
                    validation={{
                      pattern: {
                        value: /^(01[016789]{1})-?[0-9]{3,4}-?[0-9]{4}$/,
                        message: '유효한 전화번호를 입력하세요.',
                      },
                    }}
                  />
                  <TbTextarea
                    label="고객 요청 사항"
                    id="request"
                    name={'request'}
                    placeholder="고객 요청사항입니다."
                    rows={10}
                    register={register}
                    errors={errors}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col sm="4">
              <Card>
                <div className="card-header">
                  <h5>작업 관리</h5>
                </div>
                <CardBody>
                  <Row>
                    <TbSelect
                      label="담당 매니저"
                      name="manager"
                      options={enumType?.managerList ? enumType.managerList : []}
                      horizontal={true}
                      register={register}
                      errors={errors}
                      validation={{ required: true }}
                    />
                    <TbSelect
                      label="플랫폼"
                      name="platform"
                      options={enumType?.platformList ? enumType.platformList : []}
                      horizontal={true}
                      register={register}
                      errors={errors}
                      validation={{ required: true }}
                    />
                    <TbSelect
                      label="프로세스"
                      name="process"
                      options={enumType?.processList ? enumType.processList : []}
                      horizontal={true}
                      register={register}
                      errors={errors}
                    />
                    <TbSelect
                      label="온보딩 상태"
                      name="onBoarding"
                      options={enumType?.onBoardingList ? enumType.onBoardingList : []}
                      horizontal={true}
                      register={register}
                      errors={errors}
                    />
                    <TbSelect
                      label="디자인 상태"
                      name="design"
                      options={enumType?.designList ? enumType.designList : []}
                      horizontal={true}
                      register={register}
                      errors={errors}
                    />
                    <TbSelect
                      label="명함 제작"
                      name="businessCard"
                      options={enumType?.businessCardList ? enumType.businessCardList : []}
                      horizontal={true}
                      register={register}
                      errors={errors}
                    />
                    <TbSelect
                      label="우선순위"
                      name="priority"
                      options={enumType?.priorityList ? enumType.priorityList : []}
                      horizontal={true}
                      register={register}
                      errors={errors}
                    />
                    <TbDatePicker
                      label={'시작일'}
                      control={control}
                      name={'startDate'}
                      errors={errors}
                      horizontal={true}
                      dateFormat={'yyyy-MM-dd'}
                      onChange={(date: Date | null) => {
                        if (date) {
                          setValue('startDate', date);
                        }
                      }}
                    />
                    <TbDatePicker
                      label={'종료일'}
                      control={control}
                      name={'endDate'}
                      errors={errors}
                      horizontal={true}
                      dateFormat={'yyyy-MM-dd'}
                      onChange={(date: Date | null) => {
                        if (date) {
                          setValue('endDate', date);
                        }
                      }}
                    />
                    <TbTextarea
                      label="관리자 메모"
                      id="memo"
                      name={'memo'}
                      placeholder="메모를 작성해 주세요."
                      rows={10}
                      register={register}
                      errors={errors}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col sm={4}>
              <Card>
                <div className="card-header">
                  <h5>결제 정보</h5>
                </div>
                <CardBody>
                  <TbSelect
                    label="결제 방식"
                    name="payMethod"
                    options={enumType?.payMethodList ? enumType.payMethodList : []}
                    horizontal={true}
                    register={register}
                    errors={errors}
                  />
                  <TbInput
                    label="견적(원)"
                    name={'price'}
                    type="number"
                    horizontal={true}
                    placeholder={'0'}
                    register={register}
                    errors={errors}
                  />
                  <TbInput
                    label="세금계산서 이메일"
                    name={'email'}
                    type="text"
                    horizontal={true}
                    register={register}
                    errors={errors}
                  />
                  <TbRadioBoolean
                    control={control}
                    label={'입금 완료'}
                    optionLabel={['입금', '미입금']}
                    name={'paid'}
                    horizontal={true}
                    register={register}
                    errors={errors}
                  />
                  <TbRadioBoolean
                    control={control}
                    label={'계산서 발행 완료'}
                    optionLabel={['발행', '미발행']}
                    name={'payment'}
                    horizontal={true}
                    register={register}
                    errors={errors}
                  />
                  <TbTextarea
                    label="결제/환불 메모"
                    id="refund"
                    name={'refund'}
                    placeholder="환불 및 결제 관련 메모"
                    rows={10}
                    register={register}
                    errors={errors}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
    </Fragment>
  );
};
export default CreateWorkflow;
