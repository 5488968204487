import React, { CSSProperties, useState } from 'react';
import styled, { css } from 'styled-components';
import { toast } from 'react-toastify';

interface PropsType {
  value: string | number;
  onChange: (newValue: any) => void;
  style?: CSSProperties;
  isShowCopy?: boolean;
}
const WFColInputText = ({ value, onChange, style, isShowCopy }: PropsType) => {
  const [inputValue, setInputValue] = useState<string | number>(value || '');
  const [isCopy, setIsCopy] = useState<boolean>(false);

  /**
   * 클립보드 저장
   * */
  const copyToClipboard = async () => {
    try {
      if (inputValue) {
        await navigator.clipboard.writeText(inputValue.toString());
        toast.success('텍스트가 복사되었습니다.');
      }
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <Container onMouseEnter={() => setIsCopy(true)} onMouseLeave={() => setIsCopy(false)}>
      {isShowCopy && isCopy && (
        <CopyBtn onClick={copyToClipboard} className={'btn btn-xs btn-secondary'}>
          복사
        </CopyBtn>
      )}
      <TextInput
        style={style}
        value={inputValue}
        onBlur={() => {
          // Blur 시에만 변경 사항을 부모로 전달한다.
          if (value !== inputValue) {
            onChange(inputValue);
          }
        }}
        onChange={(e) => {
          const newValue = e.target.value;
          setInputValue(newValue);
        }}
      />
    </Container>
  );
};

export default React.memo(WFColInputText);
export const DefaultInputStyle = css`
  position: relative !important;
  border: 0;
  width: 100%;
  height: 23px;
  background-color: transparent;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.7px;
  &:hover,
  &:focus,
  &:active {
    outline: 2px solid #0b5ed7 !important;
    outline-offset: -1px;
  }
`;
const Container = styled.div`
  position: relative;
  width: 100%;
`;
const TextInput = styled.input`
  ${DefaultInputStyle};
  position: absolute;
  top: 0;
  left: 0;
`;

const CopyBtn = styled.div`
  position: absolute;
  right: 0;
  font-size: 10px;
  top: 3px;
  height: 18px;
  width: 27px;
  padding-left: 2px;
  padding-right: 2px;
  z-index: 1;
`;
