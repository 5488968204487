import React, { CSSProperties, ReactElement } from 'react';
import { FormGroup } from 'reactstrap';
import { FormCheck } from 'react-bootstrap';
import FormCheckInput from 'react-bootstrap/FormCheckInput';
import FormCheckLabel from 'react-bootstrap/esm/FormCheckLabel';
import styled, { css } from 'styled-components';

interface InputProps {
  label?: string;
  message?: string | ReactElement;
  id?: string;
  name: string;
  type: 'radio' | 'checkbox';
  value?: any;
  formGroupStyle?: CSSProperties;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  horizontal?: boolean;
  register?: any;
  errors?: any;
  validation?: any;
}
const TbCheckbox = ({
  label,
  message,
  id,
  name,
  type,
  value,
  formGroupStyle,
  checked,
  onChange,
  horizontal = false,
  register,
  errors,
  validation,
}: InputProps) => {
  return (
    <TBFormGroup horizontal={horizontal.toString()} style={{ ...formGroupStyle }}>
      <FormCheck>
        <FormCheckInput
          id={id ? id : `${name}-${label}`}
          type={type}
          name={name}
          value={value}
          checked={checked}
          className={type === 'radio' ? 'radio_animated' : 'checkbox_animated'}
          {...register(name, {
            ...validation,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              if (onChange) onChange(e);
            },
          })}
        />
        {label && (
          <FormCheckLabel htmlFor={id ? id : `${name}-${label}`} className={'m-b-0'}>
            {label}
            {/*{validation?.required && <span className={'text-danger'}>(필수)</span>}*/}
          </FormCheckLabel>
        )}
      </FormCheck>
      {errors && errors[name] && (
        <div className="text-danger mt-1 m-t-0">
          {errors[name]?.message ? errors[name].message : `${label}을/를 선택하세요.`}
        </div>
      )}
      {message && <div className={'mt-1'}>{message}</div>}
    </TBFormGroup>
  );
};

export default React.memo(TbCheckbox);

const TBFormGroup = styled(FormGroup)`
  margin-bottom: 8px !important;
  ${(props) =>
    props.horizontal === 'true' &&
    css`
      display: inline-block;
      margin-right: 20px;
    `}
`;
