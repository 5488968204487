import React, { ReactElement } from 'react';
import { TBFormGroup, TBLabel } from '../../styledComponents';

interface DefaultProps {
  label?: string;
  message?: string | ReactElement;
  id?: string;
  name?: string;
  accept?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  multiple?: boolean;
  readonly?: boolean;
  placeholder?: string;
  disabled?: boolean;
  horizontal?: boolean;
  register?: any;
  validation?: any;
  errors?: any;
}

const TbInputFile = ({
  label = '',
  message = '',
  name = 'file',
  accept = '',
  onChange = undefined,
  multiple = false,
  horizontal = false,
  register = undefined,
  validation = undefined,
  errors = {},
}: DefaultProps) => {
  return (
    <TBFormGroup horizontal={horizontal.toString()}>
      {!label ? null : (
        <TBLabel horizontal={horizontal.toString()}>
          {label}
          {validation?.required && <span className={'text-danger'}>(필수)</span>}
        </TBLabel>
      )}
      <div className={'w-100'}>
        <input
          name={name}
          type={'file'}
          className={`form-control ${errors?.[name] && 'is-invalid'}`}
          accept={accept}
          multiple={multiple}
          {...register?.(name, {
            ...validation,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              if (onChange) onChange(e);
            },
          })}
        />
        {errors && errors[name] && (
          <div className="text-danger mt-1">{errors[name].message || `${label}을/를 선택해주세요.`}</div>
        )}
        {message && <div className={'mt-1'}>{message}</div>}
      </div>
    </TBFormGroup>
  );
};

export default React.memo(TbInputFile);
