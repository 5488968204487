import APIClient from './APIClient';
import { PageInfo } from './types';

export interface ReqTaskPage extends PageInfo {
  status?: 'REQUEST' | 'PROCEEDING' | 'COMPLETED' | 'CANCELED';
  idBrand?: number;
}

/** 작업 내역 **/
// 작업 페이징
export const getTaskPage = async (params: ReqTaskPage) => {
  const response = await APIClient.get(`/v2/api/task/page`, {
    params,
  });
  return response.data;
};

// 작업 상세
export const getTask = async (id: string) => {
  const response = await APIClient.get(`/v2/api/task/${id}`);
  return response.data;
};

// 작업 수정 (PATCH)
export const patchTask = async (id: string, data: any) => {
  const response = await APIClient.patch(`/v2/api/task/${id}`, data);
  return response.data;
};

// 작업 삭제
export const deleteTask = async (id: string) => {
  const response = await APIClient.delete(`/v2/api/task/${id}`); // Ensure id is passed in the body
  return response.data;
};

// 작업 생성
export const postTask = async (data: any) => {
  const response = await APIClient.post(`/v2/api/task`, data);
  return response.data;
};

/** 상품 카테고리 **/
// 작업 카테고리 페이징
export const getTaskProductCategoryPage = async (params: PageInfo) => {
  const response = await APIClient.get(`/v2/api/task/product/category/page`, {
    params,
  });
  return response.data;
};
// 상품 카테고리 상세
export const getTaskProductCategory = async (id: string) => {
  const response = await APIClient.get(`/v2/api/task/product/category/${id}`);
  return response.data;
};
// 상품 카테고리 생성
export const postTaskProductCategory = async (data: any) => {
  const response = await APIClient.post(`/v2/api/task/product/category`, data);
  return response.data;
};
// 상풍 카테고리 수정
export const patchTaskProductCategory = async (id: string, data: any) => {
  const response = await APIClient.patch(`/v2/api/task/product/category/${id}`, data);
  return response.data;
};
// 상품 카테고리 삭제
export const deleteTaskProductCategory = async (id: string) => {
  const response = await APIClient.delete(`/v2/api/task/product/category/${id}`);
  return response.data;
};

/** 작업 상품 **/
// 작업 페이징
export const getTaskProductPage = async (params: PageInfo) => {
  const response = await APIClient.get(`/v2/api/task/product/page`, {
    params,
  });
  return response.data;
};
// 상품 상세
export const getTaskProduct = async (id: string) => {
  const response = await APIClient.get(`/v2/api/task/product/${id}`);
  return response.data;
};
// 상품 생성
export const postTaskProduct = async (data: any) => {
  const response = await APIClient.post(`/v2/api/task/product`, data);
  return response.data;
};
// 상풍 수정
export const patchTaskProduct = async (id: string, data: any) => {
  const response = await APIClient.patch(`/v2/api/task/product/${id}`, data);
  return response.data;
};
// 상품 삭제
export const deleteTaskProduct = async (id: string) => {
  const response = await APIClient.delete(`/v2/api/task/product/${id}`);
  return response.data;
};
