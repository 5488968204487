import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { RootState } from '../index';

interface AuthState {
  userData: any;
  userRole: string | null;
}

const initialState: AuthState = {
  userData: null,
  userRole: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserData(state, action: PayloadAction<any>) {
      state.userData = action.payload;
    },
    setUserRole(state, action: PayloadAction<string>) {
      // TODO 유저 정보를 따로 불러오지 않기 때문에 임시로 토큰에 들어있는 userRole 값을 사용하기 위해 아래 코드를 작성.
      const base64Url = action.payload.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join(''),
      );
      const decoded = JSON.parse(jsonPayload);
      state.userRole = decoded.authorities?.[0];
    },
  },
});

// export const isAuthenticated = (state: RootState) => !!state.user.userData;

export const { updateUserData, setUserRole } = userSlice.actions;

export default userSlice.reducer;
