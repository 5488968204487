/**
 * 요청의 ContentType이 'multipart/form-data' 인경우 활용.
 * */
export const createFormData = (body: any, file?: any, bodyName?: string) => {
  const formData = new FormData();
  formData.append(
    bodyName || 'body',
    new Blob(
      [
        JSON.stringify({
          ...body,
        }),
      ],
      {
        type: 'application/json',
      },
    ),
  );
  if (file) {
    formData.append('file', file);
  }
  return formData;
};

/**
 * 오브젝트를 FormData 로 변환
 * */
export const convertToFormData = (data: any) => {
  const formData = new FormData();

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];

      if (Array.isArray(value)) {
        // 배열인 경우 각 요소를 개별 항목으로 추가
        value.forEach((item) => {
          formData.append(key, item);
        });
      } else if (typeof value === 'object' && !(value instanceof File)) {
        // 객체인 경우 JSON 문자열로 변환하여 추가
        formData.append(key, JSON.stringify(value));
      } else {
        // 단일 값 또는 파일인 경우 그대로 추가
        formData.append(key, value || '');
      }
    }
  }
  return formData;
};
