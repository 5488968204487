import APIClient from './APIClient';
import { convertToFormData } from '../utils/formUtils';

export interface ReqFileUpload {
  path?: string;
}

/**
 * [리소스 버킷] 업로드 파일
 * */
export const resourceUploadFile = async (data: { file: File; body: ReqFileUpload }) => {
  const formData = convertToFormData(data.body);
  if (data.file) {
    formData.append('file', data.file);
  }
  const response = await APIClient.post(`/v2/api/file/upload`, formData, {
    headers: {
      'content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

/**
 * [리소스 버킷] 업로드 이미지
 * */
export const resourceUploadImage = async (data: { file: File; body: ReqFileUpload }) => {
  const formData = convertToFormData(data.body);
  if (data.file) {
    formData.append('file', data.file);
  }
  const response = await APIClient.post(`/v2/api/file/upload-image`, formData, {
    headers: {
      'content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

/**
 * [리소스 파일] 파일 삭제
 * */
export const resourceDeleteFile = async (url: string) => {
  const response = await APIClient.delete(`/v2/api/file/delete?url=${url}`);
  return response.data;
};

/**
 * TODO [스토리지 버킷] 업로드 파일
 * */
