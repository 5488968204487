import React, { Fragment, useCallback, useEffect, useState } from 'react';
import useApiError from '../../../hooks/useApiError';
import { useMutation, useQuery } from 'react-query';
import { updateContact, UpdateReqContact } from '../../../api/contactAPI';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import TbModal from '../../../components/common/ui/TbModal';
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap';
import Breadcrumb from '../../../components/common/breadcrumb';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import { getLogoQuest } from '../../../api/logoquest';
import LogoWorkflowSearch from '../components/workflowSearch';

interface ContactData {
  id?: string;
  userName?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
  industry?: string;
  price?: string;
  funnel?: string;
  keywords?: string[];

  target?: string;
  age?: string;
  slogan?: string;
  theme?: string;
  symbol?: string;
  service?: string;
  wishlist?: string;

  fontStyle?: string;
  logoColor?: string;
  logoStyle?: string;
  imageUrl1?: string;
  imageUrl2?: string;
  imageUrl3?: string;

  status?: 'REQUEST' | 'PROCEEDING' | 'COMPLETED' | 'CONVERSION';
  memo?: string;
}

// const statusList: any[] = [
//   { label: '신규 문의', value: 'REQUEST' },
//   { label: '상담중', value: 'PROCEEDING' },
//   { label: '상담 완료', value: 'COMPLETED' },
//   { label: '고객 전환', value: 'CONVERSION' },
// ];

const UpdateLogoQuest: React.FC = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const patchContactMutation = useMutation(({ id, data }: { id: string; data: any }) => updateContact(id, data));
  const navigate = useNavigate();
  const { id: id } = useParams<{ id: string }>();
  const [, setIsLoading] = useState<boolean>(false);
  const [isConnectBrandModal, setIsConnectBrandModal] = useState(false);

  // Form
  const { handleSubmit, setValue } = useForm<ContactData>();

  const { data: defaultData } = useQuery(['getLogoQuest', id], () => getLogoQuest(id as string), {
    enabled: !!id,
    onError: (error) => {
      handleApiError(error);
    },
  });

  useEffect(() => {
    if (defaultData) {
      console.debug(':::갱신', defaultData);
      setValue('name', defaultData.name);
      setValue('userName', defaultData.userName);
      setValue('email', defaultData.email);
      setValue('phoneNumber', defaultData.phoneNumber);
      setValue('industry', defaultData.industry);
      setValue('price', defaultData.price);
      setValue('funnel', defaultData.funnel);
      setValue('keywords', defaultData.keywords);

      setValue('target', defaultData.target);
      setValue('age', defaultData.age);
      setValue('slogan', defaultData.slogan);
      setValue('theme', defaultData.theme);
      setValue('symbol', defaultData.symbol);
      setValue('service', defaultData.service);
      setValue('wishlist', defaultData.wishlist);

      setValue('fontStyle', defaultData.fontStyle);
      setValue('logoColor', defaultData.logoColor);
      setValue('logoStyle', defaultData.logoStyle);
      setValue('imageUrl1', defaultData.imageUrl1);
      setValue('imageUrl2', defaultData.imageUrl2);
      setValue('imageUrl3', defaultData.imageUrl3);

      setValue('status', defaultData.status);
      setValue('memo', defaultData.memo);
    }
  }, [defaultData]);

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const reqBody = {
      ...data,
      company: data.company ? data.company : null,
      name: data.name ? data.name : null,
      email: data.email ? data.email : null,
      phoneNumber: data.phoneNumber ? data.phoneNumber : null,
      status: data.status ? data.status : null,
      memo: data.memo ? data.memo : null,
    } as UpdateReqContact;
    if (id && Number(id) > 0) {
      try {
        await patchContactMutation.mutateAsync({
          id: String(id),
          data: { ...reqBody },
        });
        navigate(`/admin/logoquest/update/${id}`);
        toast.success(`수정되었습니다.`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 10000,
        });
        setIsLoading(false);
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  const onCompleteConnect = useCallback(() => {
    setIsConnectBrandModal(false);
  }, []);

  return (
    <Fragment>
      {/** 브랜드 연결 모달 */}
      <TbModal
        isOpen={isConnectBrandModal}
        headerTitle={'브랜드 연결'}
        isShowFooter={false}
        toggle={() => setIsConnectBrandModal(!isConnectBrandModal)}
      >
        {isConnectBrandModal && (
          <LogoWorkflowSearch
            idLogoQuest={Number(id)}
            label={'브랜드명으로 검색하세요.'}
            onComplete={onCompleteConnect}
          />
        )}
      </TbModal>
      <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
        <Breadcrumb parent="문의" title="수정">
          <div className="mb-0">
            {/*{id ? (*/}
            {/*  <Button onClick={toggleModal} color="danger" className="me-3" type={'button'}>*/}
            {/*    삭제*/}
            {/*  </Button>*/}
            {/*) : (*/}
            {/*  ''*/}
            {/*)}*/}
            <Button color="danger" className="me-3" onClick={() => setIsConnectBrandModal(true)}>
              브랜드 연결하기
            </Button>
            {/*<Button color="secondary" className="me-3" type="submit">*/}
            {/*  {isLoading ? <TbLoading /> : '수정'}*/}
            {/*</Button>*/}
            <Link to={`/admin/logoquest/list`}>
              <Button color="primary">목록</Button>
            </Link>
          </div>
        </Breadcrumb>
        <Container fluid={true}>
          <Row className="details">
            <Col sm="8">
              <Card>
                <div className="card-header">
                  <h5>신청자 정보</h5>
                </div>
                <CardBody>
                  <Row>
                    <Col sm="6" className={'detail-item'}>
                      담당자 성함
                      <br />
                      <span>{defaultData?.userName}</span>
                    </Col>
                    <Col sm="6" className={'detail-item'}>
                      기업명 / 브랜드명
                      <br />
                      <span>{defaultData?.name}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" className={'detail-item'}>
                      이메일
                      <br />
                      <span>{defaultData?.email}</span>
                    </Col>
                    <Col sm="6" className={'detail-item'}>
                      핸드폰 번호
                      <br />
                      <span>{defaultData?.phoneNumber}</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="6" className="detail-item">
                      심볼
                      <br />
                      <span>{defaultData?.symbol}</span>
                    </Col>

                    <Col sm="6" className={'detail-item'}>
                      유입경로
                      <br />
                      <span>{defaultData?.funnel}</span>
                    </Col>
                  </Row>
                  {/*@@@@@@@ @@@@@@@@@@@@@@*/}

                  <Row>
                    <Col sm="6" className="detail-item">
                      메인 컬러
                      <br />
                      <span>{defaultData?.logoColor}</span>
                    </Col>

                    <Col sm="6" className={'detail-item'}>
                      로고 스타일
                      <br />
                      <span>{defaultData?.logoStyle}</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="6" className={'detail-item'}>
                      서비스 설명
                      <br />
                      <span>{defaultData?.service}</span>
                    </Col>
                    <Col sm="6" className={'detail-item'}>
                      문의 날짜
                      <br />
                      <span>{moment(defaultData?.createdDate).format('YYYY-MM-DD HH:mm:ss')}</span>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            {/*<Col sm="4">*/}
            {/*  <Card>*/}
            {/*    <div className="card-header">*/}
            {/*      <h5>문의 정보</h5>*/}
            {/*    </div>*/}
            {/*    <CardBody>*/}
            {/*      <TbSelect*/}
            {/*        label="상태"*/}
            {/*        isPlaceholder={false}*/}
            {/*        name="status"*/}
            {/*        options={statusList ? statusList : []}*/}
            {/*        register={register}*/}
            {/*        errors={errors}*/}
            {/*      />*/}
            {/*      <TbTextarea*/}
            {/*        label="메모"*/}
            {/*        id="memo"*/}
            {/*        name={'memo'}*/}
            {/*        placeholder="메모를 작성해 주세요."*/}
            {/*        rows={10}*/}
            {/*        register={register}*/}
            {/*        errors={errors}*/}
            {/*      />*/}
            {/*    </CardBody>*/}
            {/*  </Card>*/}
            {/*</Col>*/}
          </Row>
        </Container>
      </Form>
    </Fragment>
  );
};

export default UpdateLogoQuest;
