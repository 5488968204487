import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useQuery } from 'react-query';
import useApiError from '../../hooks/useApiError';
import Breadcrumb from '../../components/common/breadcrumb';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import TbFilter from '../../components/common/ui/TbFilter';
import { Col, Row } from 'reactstrap';
import TbSelect from '../../components/common/ui/form/TbSelect';
import TbInput from '../../components/common/ui/form/TbInput';
import { getTaskPage, ReqTaskPage } from '../../api/taskAPI';
import { taskColumns } from './data/task-columns';
import { formatEndDate, formatStartDate } from '../../utils/formatUtils';
import { cellsStyle, headCellsStyle, rowsStyle } from '../../data/customizer/react-data-table-config';

const statusList: any[] = [
  { label: '신청 완료', value: 'REQUEST' },
  { label: '작업 진행중', value: 'PROCEEDING' },
  { label: '작업 완료', value: 'COMPLETED' },
  { label: '작업 취소', value: 'CANCELED' },
];

function TaskList() {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();
  // Query
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const idBrand = queryParams.get('idBrand');
  const query = queryParams.get('query');
  const status = queryParams.get('status');

  // Pagination
  const [listData, setListData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [reqParams, setReqParams] = useState<ReqTaskPage>({
    query: query || '',
    startDate: '',
    endDate: '',
    page: 0,
    size: 30,
    sort: '',
    status: status ? (status as 'REQUEST' | 'PROCEEDING' | 'COMPLETED' | 'CANCELED') : undefined,
    idBrand: idBrand ? Number(idBrand) : undefined,
  });

  /**
   * 페이지 데이터 요청.
   * */
  const { isLoading } = useQuery(
    ['getTaskPage', { reqParams }],
    () => getTaskPage({ ...reqParams, idBrand: Number(idBrand) || undefined }),
    {
      onSuccess: (data) => {
        setListData(data?._embedded?.tasks || []);
        setTotalRows(data?.page?.totalElements | 0);
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 페이지 변경 핸들러
   * */
  const handlePageChange = (page: number) => {
    setReqParams({
      ...reqParams,
      page: page - 1,
    });
  };

  /**
   * 페이지 사이즈 변경
   * */
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParams({
      ...reqParams,
      size: newPerPage,
      page: page - 1,
    });
  };

  // TODO 크레딧 적립/차감
  // const handleCredit = (type: 'PLUS' | 'MINUS') => {
  //   if (type === 'PLUS') {
  //     console.log('Plus');
  //   } else if (type === 'MINUS') {
  //     console.log('Minus');
  //   }
  // };

  // 검색 핸들러
  const onSubmitSearch = (filters: any) => {
    setReqParams({
      ...reqParams,
      query: filters.query || '',
      startDate: filters.startDate ? formatStartDate(filters.startDate) : null,
      endDate: filters.endDate ? formatEndDate(filters.endDate) : null,
      idBrand: filters.idBrand || undefined,
      status: filters.status || undefined,
    });
    // Construct URL query
    const searchParams = new URLSearchParams();

    if (filters.idBrand) searchParams.set('idBrand', filters.idBrand.toString());
    if (filters.query) searchParams.set('query', filters.query);
    if (filters.status) searchParams.set('status', filters.status);

    //
    navigate({
      pathname: window.location.pathname,
      search: searchParams.toString(),
    });
  };

  // 행 클릭 핸들러
  const handleRowClicked = (row: any) => {
    const params = new URLSearchParams();

    if (idBrand) params.set('idBrand', idBrand.toString());
    if (reqParams.query) params.set('query', reqParams.query);
    if (reqParams.status) params.set('status', reqParams.status);

    navigate(`/admin/task/update/${row.id}?${params.toString()}`);
  };

  return (
    <div>
      <Breadcrumb parent="디자이너" title="목록">
        <Link className="btn btn-primary" to={`/admin/task/create`}>
          새로 등록
        </Link>
      </Breadcrumb>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <TbFilter onSubmit={onSubmitSearch}>
              {({ register, errors, setValue }) => {
                useEffect(() => {
                  setValue('query', query);
                  setValue('idBrand', idBrand);
                  setValue('status', status);
                }, [idBrand]);
                return (
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">브랜드 ID</label>
                        </Col>
                        <Col sm={10}>
                          <TbInput
                            name={'idBrand'}
                            placeholder={'브랜드 ID'}
                            type="text"
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">상태</label>
                        </Col>
                        <Col sm={10}>
                          <TbSelect
                            placeholder={'전체'}
                            name="status"
                            options={statusList ? statusList : []}
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              }}
            </TbFilter>
          </div>
          <div className={'col-xl-12'}>
            <div className={'card-table-body'}>
              <DataTable
                noHeader
                columns={taskColumns}
                data={listData}
                progressPending={isLoading}
                highlightOnHover
                pointerOnHover
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationPerPage={30}
                paginationRowsPerPageOptions={[5, 10, 30, 50, 100]}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onRowClicked={handleRowClicked}
                dense={true}
                customStyles={{
                  rows: rowsStyle,
                  headCells: headCellsStyle,
                  cells: cellsStyle,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(TaskList);
