import { createSlice } from '@reduxjs/toolkit';
import ConfigDB from '../../data/customizer/config.js';

const initialState = {
  customizer: ConfigDB.data,
  configData: {},
  layout: ConfigDB.data.settings.layout_type || 'ltr',
  sidebar_types: ConfigDB.data.settings.sidebar || {},
  settings: ConfigDB.data.settings.sidebar_setting || '',
  color: ConfigDB.data.color || {},
  mix_layout: ConfigDB.data.color.mix_layout || '',
};

const customizerSlice = createSlice({
  name: 'customizer',
  initialState,
  reducers: {
    addCustomizer: (state) => {
      state.customizer = ConfigDB.data;
    },
    addLayout: (state, action) => {
      state.customizer.settings.layout_type = action.payload;
      state.layout = action.payload;
    },
    addSidebarTypes: (state, action) => {
      state.customizer.settings.sidebar = action.payload.wrapper;
      state.sidebar_types = action.payload;
    },
    addSidebarSettings: (state, action) => {
      state.customizer.settings.sidebar_setting = action.payload;
      state.settings = action.payload;
    },
    addColor: (state, action) => {
      state.customizer.color = {
        ...state.customizer.color,
        ...action.payload,
      };
      state.color = action.payload;
    },
    addMixLayout: (state, action) => {
      state.customizer.color.mix_layout = action.payload;
      state.mix_layout = action.payload;
    },
  },
});

export const { addCustomizer, addLayout, addSidebarTypes, addSidebarSettings, addColor, addMixLayout } =
  customizerSlice.actions;

export default customizerSlice.reducer;
