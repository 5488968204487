import React from 'react';
import { TBFormGroup, TBLabel } from '../../styledComponents';

interface TbTextareaProps {
  label: string;
  id: string;
  name: string;
  placeholder?: string;
  readonly?: boolean;
  disabled?: boolean;
  rows?: number;
  cols?: number;
  horizontal?: boolean;
  register: any; // The register function from react-hook-form
  errors: any; // The errors object from react-hook-form
  validation?: any; // Validation rules for react-hook-form
}

const TbTextarea: React.FC<TbTextareaProps> = ({
  label = '',
  id = '',
  name = '',
  placeholder = '',
  readonly = false,
  disabled,
  rows = 5,
  cols = 5,
  horizontal = false,
  register = undefined,
  errors = {},
  validation = undefined,
}) => {
  return (
    <TBFormGroup horizontal={horizontal.toString()}>
      <TBLabel
        horizontal={horizontal.toString()}
        for={id}
        dangerouslySetInnerHTML={{
          __html: `${label} ${validation?.required ? '<span class="text-danger">(필수)</span>' : ''}`,
        }}
      ></TBLabel>

      <div className={'w-100'}>
        <textarea
          className="form-control"
          id={id}
          name={name || id}
          rows={rows}
          cols={cols}
          placeholder={placeholder}
          readOnly={readonly}
          disabled={disabled}
          {...register(name, validation)}
        ></textarea>

        {errors[name] && (
          <div className="text-danger mt-1">
            {errors[name]?.message ? errors[name].message : `${label}을/를 입력하세요.`}
          </div>
        )}
      </div>
    </TBFormGroup>
  );
};

export default React.memo(TbTextarea);
