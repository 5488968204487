import React, { useEffect, useRef, useState } from 'react';
import { EditorElementType, getEditorElement, postBatchEditorElement } from '../../../api/editorElementAPI';
import PreviewImageFile from './PreviewImageFile';
import styled from 'styled-components';
import TbLoading from '../../../components/common/ui/TbLoading';
import { useMutation, useQuery } from 'react-query';
import useApiError from '../../../hooks/useApiError';
import { CheckCircle, XCircle } from 'react-feather';

export interface EditorElementItem {
  type: EditorElementType;
  idCategories?: string[];
  idCollections?: string[];
  tagList?: string[];
  file: File;
  // 응답 후 추가 데이터 필드
  idEditorElement?: number;
  active: boolean;
}
interface DefaultProps {
  data: EditorElementItem;
  onInitTask?: (idEditorElement: number) => void; // 큐 태스크 초기화.
  onCompleteTask?: (idEditorElement: number) => void; // 큐 태스크 완료.
  listQueue: number[]; // 완료 여부 확인 대상.
}
const BatchImageItem = ({ data: elementData, onInitTask, onCompleteTask, listQueue }: DefaultProps) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const batchImageMutation = useMutation(postBatchEditorElement);
  const [idEditorElement, setIdEditorElement] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const { data: resElementData, refetch } = useQuery(
    [`getEditorElement${idEditorElement}`],
    () => getEditorElement(idEditorElement as number),
    {
      enabled: !!idEditorElement,
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  // 요소 등록
  useEffect(() => {
    const mutate = async () => {
      try {
        const reqBody = {
          type: elementData.type,
          idCategories: elementData.idCategories,
          idCollections: elementData.idCollections,
          tagList: elementData.tagList,
        };
        const resultData = await batchImageMutation.mutateAsync({
          file: elementData.file,
          body: reqBody,
        });
        setIdEditorElement(resultData.idEditorElement);
        // 최초 큐 대열에 태스크 등록.
        onInitTask?.(resultData.idEditorElement);
      } catch (error) {
        handleApiError(error);
        setIsInvalid(true);
      }
    };
    if (elementData && elementData.file) {
      mutate();
    }
  }, []);

  // 폴링 요청
  useEffect(() => {
    // 1. 로딩 상태인 경우.
    // 2. 요청이 완료되어 id가 생성된 경우.
    // then 폴링 시작.
    if (isLoading && idEditorElement) {
      // 인덱스 0번 부터 3개 안에 해당 아이디가 존재하는 경우 폴링 시작.
      const taskQueue = listQueue.slice(0, 3);
      if (taskQueue.includes(idEditorElement)) {
        // 폴링 시작.
        startPolling();
      }
    }
  }, [isLoading, idEditorElement, listQueue]);

  // 폴링 완료 조건.
  useEffect(() => {
    if (resElementData?.active) {
      setIsLoading(false);
      setIsSuccess(true);
      onCompleteTask?.(resElementData.idEditorElement);
    }
  }, [resElementData]);

  // 폴링 시작.
  const refPollingCount = useRef(0);
  const refInterval = useRef<NodeJS.Timeout | null>(null);
  const startPolling = () => {
    if (refInterval.current) clearInterval(refInterval.current); // 기존 interval이 있으면 제거

    refInterval.current = setInterval(() => {
      if (refPollingCount.current < 10 && !resElementData?.active) {
        // 최대 5회까지만 실행
        refetch();
        refPollingCount.current += 1;
      } else {
        clearInterval(refInterval.current as NodeJS.Timeout); // 5회 실행 후 폴링 중단
        setIsInvalid(true);
        // 실패시 제거
        onCompleteTask?.(idEditorElement as number);
      }
    }, 1500);
  };

  // useEffect를 사용하여 resElementData 값 변경 시 폴링 중단
  useEffect(() => {
    if (resElementData?.active && refInterval.current) {
      clearInterval(refInterval.current); // active가 true이면 interval 중단
      refInterval.current = null;
      refPollingCount.current = 0;

      // 성공 시 제거
      onCompleteTask?.(idEditorElement as number);
    }
  }, [resElementData]);

  return (
    <Container>
      <PreviewWrap>
        <PreviewImageFile file={elementData.file} />
        {isLoading && (
          <LoadingWrap>
            {!isInvalid ? (
              <TbLoading size={26} color={'#fff'} />
            ) : (
              <div className={'text-danger'} style={{ fontSize: 12 }}>
                업로드중 오류가 발생했습니다.
              </div>
            )}
          </LoadingWrap>
        )}
        {!isLoading && (
          <StatusWrap>
            {isSuccess ? <CheckCircle size={26} color={'rgb(38,206,27)'} /> : <XCircle size={26} color={'#c50e0e'} />}
          </StatusWrap>
        )}
      </PreviewWrap>
      <Title>{elementData.file.name}</Title>
    </Container>
  );
};

export default React.memo(BatchImageItem);

const Container = styled.div`
  border: 1px solid #ccc;
  height: 100%;
`;
const PreviewWrap = styled.div`
  position: relative;
  background-color: #eee;
`;
const LoadingWrap = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StatusWrap = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  line-height: 0;
`;
const Title = styled.div`
  padding: 4px;
  font-size: 12px;
  font-weight: 500;
`;
