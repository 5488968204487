import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './i18n.jsx';
import * as serviceWorker from './serviceWorker';

// ** Import custom components for redux **
import { Provider } from 'react-redux';
import store from './store';
import MainRoutes from './routes';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // 다시 포커스 됐을때 API 요청
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <MainRoutes />
    </Provider>
  </QueryClientProvider>,
);

serviceWorker.unregister();
