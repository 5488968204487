import axios from 'axios';
import Cookies from 'js-cookie';

// Create an instance of axios
const APIClient = axios.create({
  baseURL: process.env.REACT_APP_API_ADMIN_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include the token in the headers
APIClient.interceptors.request.use(
  (config) => {
    const tokenName = process.env.REACT_APP_ACCESS_TOKEN_NAME || 'tidyb-admin-token';
    const token = Cookies.get(tokenName);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default APIClient;
