import React, { useEffect, useRef, useState } from 'react';
import { FormGroup } from 'reactstrap';
import { Search } from 'react-feather';
import styled from 'styled-components';

interface TbSearchProps {
  label?: string;
  id?: string;
  name?: string;
  placeholder?: string;
  defaultValue?: string;
  register: any;
  errors: any;
  validation?: any;
  onChange: (value: string) => void;
  searchData: any[];
  onSelectValue: (value: any) => void;
}

const TbSearch: React.FC<TbSearchProps> = ({
  label = '',
  id = '',
  name = '',
  defaultValue = '',
  placeholder = '',
  register = undefined,
  errors = {},
  validation = undefined,
  onChange = () => {},
  searchData = [],
  onSelectValue = () => {},
}) => {
  const [searchIndex] = useState<number>(0);
  const [isList, serIsList] = useState<boolean>(false);
  const refInput = useRef<HTMLInputElement>();

  const handleChangeValue = (item: any) => {
    if (refInput.current) {
      refInput.current.value = item.label;
    }
    onSelectValue(item.value);
    serIsList(false);
  };

  useEffect(() => {
    if (searchData && searchData.length > 0) {
      serIsList(true);
    }
  }, [searchData]);

  return (
    <FormGroup>
      <label htmlFor={id}>{label}</label>
      <TbSearchLayout>
        <input
          ref={refInput}
          className={`form-control ${errors[name] ? 'is-invalid' : ''}`}
          id={id}
          name={name}
          type="text"
          placeholder={placeholder}
          defaultValue={defaultValue || ''}
          {...(register ? register(name, validation) : {})}
          autoComplete="false"
          onChange={(e) => onChange(e.target.value)}
        />
        <span className="search-icon">
          <Search size={20} />
        </span>
        {errors[name] && (
          <span className="text-danger">
            {errors[name]?.message ? errors[name].message : `${label}을/를 입력해주세요.`}
          </span>
        )}
        {isList && searchData && searchData.length > 0 && (
          <SearchData
            searchData={searchData}
            onBlur={() => {
              if (isList) {
                serIsList(false);
              }
            }}
          >
            {searchData.map((item, index) => (
              <div className={'search-detail'} key={searchIndex + index} onClick={() => handleChangeValue(item)}>
                <div>{item.label}</div>
              </div>
            ))}
          </SearchData>
        )}
      </TbSearchLayout>
    </FormGroup>
  );
};

export default React.memo(TbSearch);

const TbSearchLayout = styled.div`
  position: relative;

  .form-control {
    padding-left: 45px;
  }

  .search-icon {
    top: calc(20px - 11px);
    left: 10px;
    position: absolute;
    color: #adb5bd;
  }
`;

const SearchData = styled.div<{ searchData: { value: string }[] }>`
  position: absolute;
  max-height: 300px;
  top: 120%;
  padding: 12px;
  z-index: 100;
  width: 100%;
  overflow: auto;
  display: ${({ searchData }) => (searchData.length > 0 ? 'block' : 'none')};
  background-color: #ffffff;
  border-radius: 15px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  .search-detail {
    cursor: pointer;
    padding: 10px 15px;
    &:hover {
      background-color: #f8f9fa;
      border-radius: 10px;
    }
  }
`;
