// src/store/settingSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getSetting } from '../../api/settingAPI'; // getSetting API 호출 함수

// SettingState 인터페이스 정의 (타입 명시)
interface SettingState {
  data: {
    defaultLocale: string;
    international: boolean;
    internationalMode: {
      koKr: boolean;
      enUs: boolean;
      zhCn: boolean;
      zhTw: boolean;
      jaJp: boolean;
    };
  } | null;
  loading: boolean;
  error: string | null;
}

// 초기 상태 설정
const initialState: SettingState = {
  data: null, // 설정 데이터
  loading: false, // 로딩 상태
  error: null, // 에러 메시지
};

// 비동기 설정 데이터 호출
export const fetchSetting = createAsyncThunk('setting/fetchSetting', async () => {
  const response = await getSetting();
  return response; // API 응답 데이터
});

const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // 비동기 호출 대기 상태 처리
      .addCase(fetchSetting.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      // 비동기 호출 성공 상태 처리
      .addCase(fetchSetting.fulfilled, (state, action: PayloadAction<SettingState['data']>) => {
        state.loading = false;
        state.data = action.payload; // 데이터를 상태에 저장
      })
      // 비동기 호출 실패 상태 처리
      .addCase(fetchSetting.rejected, (state) => {
        state.loading = false;
        state.error = '설정 데이터를 가져오는 중 오류가 발생했습니다.'; // 에러 메시지
      });
  },
});

export default settingSlice.reducer;
