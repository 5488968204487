import styled from 'styled-components';
import React from 'react';
import TbLoading from './TbLoading';

const TbLoadingPage = () => {
  return (
    <Container>
      <TbLoading size={40} />
    </Container>
  );
};

export default React.memo(TbLoadingPage);

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
