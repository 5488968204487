import React, { ReactNode, useEffect, useState } from 'react';
import { Card, CardBody, Form, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { FieldErrors } from 'react-hook-form';

interface TabNavigationProps {
  title?: string;
  children: ReactNode[]; // 각 탭의 내용을 전달
  errors: FieldErrors<any>;
}

interface InternationalMode {
  koKr: boolean;
  enUs: boolean;
  zhCn: boolean;
  zhTw: boolean;
  jaJp: boolean;
}

interface Tab {
  id: string;
  label: string;
  value: keyof InternationalMode; // 언어 모드의 키를 명시적으로 타입 지정
  error: boolean;
}

const TbLangTab: React.FC<TabNavigationProps> = ({ children, title, errors }) => {
  const [tabs, setTabs] = useState<Tab[]>([
    { id: '1', label: '<i class="flag-icon flag-icon-kr"></i> 한국어', value: 'koKr', error: false },
    { id: '2', label: '<i class="flag-icon flag-icon-us"></i> 영어', value: 'enUs', error: false },
    { id: '3', label: '<i class="flag-icon flag-icon-cn"></i> 중국어(간체)', value: 'zhCn', error: false },
    { id: '4', label: '<i class="flag-icon flag-icon-tw"></i> 중국어(번체)', value: 'zhTw', error: false },
    { id: '5', label: '<i class="flag-icon flag-icon-jp"></i> 일본어', value: 'jaJp', error: false },
  ]);
  const [activeTab, setActiveTab] = useState(tabs[0].id); // 초기 탭 설정
  const defaultData = useSelector((state: RootState) => state.setting.data);

  const toggleTab = (tabId: string) => {
    if (activeTab !== tabId) {
      setActiveTab(tabId);
    }
  };

  // 오류 감지 및 탭 상태 업데이트
  useEffect(() => {
    const updatedTabs = tabs.map((tab) => {
      // 각 탭에 해당하는 필드에 오류가 있는지 확인
      if (
        (tab.id === '1' && errors.nameKoKr) ||
        (tab.id === '2' && errors.nameEnUs) ||
        (tab.id === '3' && errors.nameZhCn) ||
        (tab.id === '4' && errors.nameZhTw) ||
        (tab.id === '5' && errors.nameJaJp) ||
        (tab.id === '1' && errors.textKoKr) ||
        (tab.id === '2' && errors.textEnUs) ||
        (tab.id === '3' && errors.textZhCn) ||
        (tab.id === '4' && errors.textZhTw) ||
        (tab.id === '5' && errors.textJaJp)
      ) {
        return { ...tab, error: true };
      }
      return { ...tab, error: false };
    });
    setTabs(updatedTabs);
  }, [errors]); // errors가 변경될 때마다 실행

  return (
    <div>
      <Form>
        {defaultData?.international && (
          <Nav tabs>
            {tabs.map(
              (tab) =>
                defaultData?.internationalMode[tab.value] && ( // 해당 언어가 국제화 모드에서 활성화되었는지 체크
                  <NavItem key={tab.id}>
                    <NavLink
                      style={{
                        color: tab.error ? '#ff5370' : '#333',
                      }}
                      className={activeTab === tab.id ? 'active' : ''}
                      onClick={() => toggleTab(tab.id)}
                      dangerouslySetInnerHTML={{ __html: tab.label }}
                    ></NavLink>
                  </NavItem>
                ),
            )}
          </Nav>
        )}

        <TabContent activeTab={activeTab}>
          {children.map((child, index) => {
            const tab = tabs[index];
            let shouldRender = defaultData?.internationalMode[tab.value];

            if (!defaultData?.international) {
              shouldRender = tab.value === 'koKr';
            }

            return (
              <TabPane key={index} tabId={tab.id}>
                <Card style={{ borderTopLeftRadius: '0', borderTopRightRadius: '0' }}>
                  {!defaultData?.international && (
                    <div className="card-header">
                      <h5>{title}</h5>
                    </div>
                  )}
                  {shouldRender && <CardBody>{child}</CardBody>}
                </Card>
              </TabPane>
            );
          })}
        </TabContent>
      </Form>
    </div>
  );
};

export default TbLangTab;
