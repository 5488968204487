// 메뉴
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

//
export const workflowColumns = (enumType: any) => [
  {
    name: 'ID',
    selector: (row: any) => row.id,
    center: true,
    width: '60px',
  },
  {
    name: '유형',
    selector: (row: any) => {
      const selectedItem = enumType.typeList?.find((item: any) => item.value === row.type);
      return selectedItem?.label;
    },
    sortable: true,
    center: true,
    wrap: true,
    width: '70px',
  },
  {
    name: '연결 계정',
    cell: (row: any) => (
      <div className={'d-flex align-items-center w-100'} title={row.connected ? row.connectEmail : 'disconnected...'}>
        <ConnectIcon style={{ backgroundColor: row.connected ? '#37d310' : '#fd2424' }} />
        <EllipsisText>{row.connected ? row.connectEmail : 'disconnected...'}</EllipsisText>
      </div>
    ),
    sortable: true,
    center: true,
    wrap: true,
    width: '100px',
  },
  {
    name: '브랜드명',
    selector: (row: any) => row.brandName,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '150px',
  },
  {
    name: '기업명',
    selector: (row: any) => row.businessName,
    sortable: true,
    center: true,
    wrap: true,
    width: '150px',
  },
  {
    name: '고객명',
    selector: (row: any) => row.userName, // 정렬 기준
    sortable: true,
    center: true,
    wrap: true,
    width: '150px',
  },
  {
    name: '플랫폼',
    selector: (row: any) => {
      const selectedItem = enumType.platformList?.find((item: any) => item.value === row.platform);
      return selectedItem.label;
    },
    sortable: true,
    center: true,
    wrap: true,
    width: '80px',
  },
  {
    name: '매니저',
    selector: (row: any) => {
      const selectedItem = enumType.managerList?.find((item: any) => item.value === row.manager);
      return selectedItem.label;
    },
    sortable: true,
    center: true,
    wrap: true,
    width: '70px',
  },
  {
    name: '온보딩 상태',
    selector: (row: any) => row.onBoarding,
    sortable: true,
    center: true,
    wrap: true,
    width: '110px',
  },
  {
    name: '디자인 상태',
    selector: (row: any) => row.design,
    sortable: true,
    center: true,
    wrap: true,
    width: '100px',
  },
  {
    name: '시작일',
    selector: (row: any) => (row.startDate ? moment(row.startDate).format('YYYY/MM/DD') : '-'),
    sortable: true,
    center: true,
    wrap: true,
    width: '90px',
  },
  {
    name: '종료일',
    selector: (row: any) => (row.endDate ? moment(row.endDate).format('YYYY/MM/DD') : '-'),
    sortable: true,
    center: true,
    wrap: true,
    width: '90px',
  },
];

const ConnectIcon = styled.div`
  width: 8px;
  height: 8px;
  margin-right: 4px;
  border-radius: 50%;
  display: inline-block;
  flex-shrink: 0;
`;

const EllipsisText = styled.div`
  white-space: nowrap;
  overflow: hidden; // 너비를 넘어가면 안보이게
  text-overflow: ellipsis;
  height: 15px;
  width: 100%;
`;
