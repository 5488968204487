import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React from 'react';
import useApiError from '../../../hooks/useApiError';
import { useMutation } from 'react-query';
import { expireSubscribe } from '../../../api/subscriptionAPI';
import { toast } from 'react-toastify';

interface ModalProps {
  isOpen: boolean;
  toggle: () => void;
  onSuccess?: () => void;
  idSubscription: number;
}

const ManageSubscribeExpireModal = ({ isOpen, toggle, onSuccess, idSubscription }: ModalProps) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const patchExpireSubscribe = useMutation(expireSubscribe);

  /**
   * 구독 초기화 설정 요청.
   * */
  const handleOnSubmit = async () => {
    try {
      await patchExpireSubscribe.mutateAsync(idSubscription);
      toast.success('구독 만료 상태로 전환되었습니다.');
      toggle();
      // 성공 콜백
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size={'md'}>
      <ModalHeader>구독 만료 설정</ModalHeader>
      <ModalBody>
        <p>- 구독 중인 계정을 만료 상태로 전환합니다.</p>
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          onClick={(e) => {
            e.preventDefault();
            toggle?.();
          }}
        >
          취소
        </Button>
        <Button onClick={handleOnSubmit} color="primary" className="me-3" type={'submit'}>
          구독 만료 전환
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(ManageSubscribeExpireModal);
