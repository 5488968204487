import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import React, { useState } from 'react';
import { ConvertLogoWrap, CovertLogo } from './ConvertFileSelector';
import styled from 'styled-components';
import TbCheckbox from '../../../../components/common/ui/form/TbCheckbox';
import { useForm } from 'react-hook-form';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import TbLoading from '../../../../components/common/ui/TbLoading';

interface DefaultProps {
  name?: string;
  logos: CovertLogo[];
}
const ConvertResult = ({ name = '[브랜드]', logos }: DefaultProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTransparent, setIsTransparent] = useState<boolean>(true);
  const { register } = useForm();

  const handleDownloadZip = () => {
    setIsLoading(true);
    const zip = new JSZip();

    const toFile = (logo: CovertLogo, format: 'svg' | 'png' | 'jpg') => {
      const folder = logo[format]?.folderName;
      const colors = ['origin', 'white', 'black'] as const;
      return colors.map((color) => ({
        name: `${name}_${logo.name}_${color}.${format}`,
        folder,
        data: logo[format]?.[color]?.url?.replace(/^data:.*;base64,/, ''),
      }));
    };

    const files = logos
      .map((logo) => {
        return [...toFile(logo, 'svg'), ...toFile(logo, 'png'), ...toFile(logo, 'jpg')];
      })
      .flat();

    // base64 데이터를 ZIP 파일에 추가
    files.forEach((file: any) => {
      zip.folder(file?.folder)?.file(file.name, file.data, { base64: true });
    });

    // ZIP 파일 생성 및 다운로드
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `${name}_로고.zip`);
      setIsLoading(false);
    });
  };

  return (
    <Card>
      <CardHeader>
        <h5>생성 결과</h5>
        <div className={'d-flex'}>
          <TbCheckbox
            label={'투명 배경'}
            name={'transparentResult'}
            type={'checkbox'}
            checked={isTransparent}
            register={register}
            formGroupStyle={{ marginBottom: '0 !important' }}
            onChange={(e) => {
              setIsTransparent(e.target.checked);
            }}
          />
          {logos.length > 0 && (
            <Button className={'m-l-10'} color={'primary'} onClick={handleDownloadZip} disabled={isLoading}>
              {isLoading ? (
                <div className={'d-flex align-items-center justify-content-center'}>
                  Loading...
                  <span>
                    <TbLoading />
                  </span>
                </div>
              ) : (
                `ZIP 다운로드`
              )}
            </Button>
          )}
        </div>
      </CardHeader>
      <CardBody>
        {logos?.map((logo) => (
          <div key={logo.id} className={'mb-3'}>
            <h4>{logo.name}</h4>
            <h6>SVG {`(${logo.svg.width}px x ${logo.svg.height}px)`}</h6>
            <Row className={'mb-3'}>
              <Col sm={4}>
                <ConvertLogoWrap isTransparent={isTransparent}>
                  <ConvertPreviewImage src={logo.svg.origin?.url} />
                </ConvertLogoWrap>
              </Col>
              <Col sm={4}>
                <ConvertLogoWrap isTransparent={isTransparent} style={{ backgroundColor: '#000' }}>
                  <ConvertPreviewImage src={logo.svg.white?.url} />
                </ConvertLogoWrap>
              </Col>
              <Col sm={4}>
                <ConvertLogoWrap isTransparent={isTransparent}>
                  <ConvertPreviewImage src={logo.svg.black?.url} />
                </ConvertLogoWrap>
              </Col>
            </Row>
            <h6>PNG {`(${logo.png?.width}px x ${logo.png?.height}px)`}</h6>
            <Row className={'mb-3'}>
              <Col sm={4}>
                <ConvertLogoWrap isTransparent={isTransparent}>
                  <ConvertPreviewImage src={logo.png?.origin?.url} />
                </ConvertLogoWrap>
              </Col>
              <Col sm={4}>
                <ConvertLogoWrap isTransparent={isTransparent} style={{ backgroundColor: '#000' }}>
                  <ConvertPreviewImage src={logo.png?.white?.url} />
                </ConvertLogoWrap>
              </Col>
              <Col sm={4}>
                <ConvertLogoWrap isTransparent={isTransparent}>
                  <ConvertPreviewImage src={logo.png?.black?.url} />
                </ConvertLogoWrap>
              </Col>
            </Row>
            <h6>JPG {`(${logo.jpg?.width}px x ${logo.jpg?.height}px)`}</h6>
            <Row className={'mb-3'}>
              <Col sm={4}>
                <ConvertLogoWrap isTransparent={isTransparent}>
                  <ConvertPreviewImage src={logo.jpg?.origin?.url} />
                </ConvertLogoWrap>
              </Col>
              <Col sm={4}>
                <ConvertLogoWrap isTransparent={isTransparent} style={{ backgroundColor: '#000' }}>
                  <ConvertPreviewImage src={logo.jpg?.white?.url} />
                </ConvertLogoWrap>
              </Col>
              <Col sm={4}>
                <ConvertLogoWrap isTransparent={isTransparent}>
                  <ConvertPreviewImage src={logo.jpg?.black?.url} />
                </ConvertLogoWrap>
              </Col>
            </Row>
            <hr className={'my-5'} />
          </div>
        ))}

        {logos.length > 0 && (
          <Button color={'primary'} block={true} onClick={handleDownloadZip} disabled={isLoading}>
            {isLoading ? (
              <div className={'d-flex align-items-center justify-content-center'}>
                Loading...
                <span>
                  <TbLoading />
                </span>
              </div>
            ) : (
              `${name}_logos.zip 다운로드`
            )}
          </Button>
        )}

        {logos.length === 0 && <div className={'text-center'}>생성된 이미지가 없습니다.</div>}
      </CardBody>
    </Card>
  );
};
export default React.memo(ConvertResult);

const ConvertPreviewImage = styled.img`
  width: 100%;
  max-width: 100%;
`;
