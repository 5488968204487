import { configureStore } from '@reduxjs/toolkit';
import customizerReducer from './reducers/customizerSlice';
import modalReducer from './reducers/modalSlice';
import userReducer from './reducers/userSlice';
import settingReducer from './reducers/settingSlice';

const store = configureStore({
  reducer: {
    customizer: customizerReducer,
    modal: modalReducer,
    user: userReducer,
    setting: settingReducer,
  },
});

// 타입 정의 추가
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
