import React, { Fragment } from 'react';
import man from '../../../assets/images/dashboard/user.png';
import { LogOut } from 'react-feather';
import { useCookies } from 'react-cookie';

const tokenName = process.env.REACT_APP_ACCESS_TOKEN_NAME || 'tidyb-admin-token';

const UserMenu = () => {
  const [, , removeCookie] = useCookies([tokenName]);
  // const navigate = useNavigate();

  const handleLogout = () => {
    // 쿠키에서 토큰 삭제
    removeCookie(tokenName, { path: '/' });
    // 로그아웃 후 로그인 페이지로 리디렉션
    // navigate(`${process.env.PUBLIC_URL}/login`);

    console.debug('로그아웃 Success::::');
  };
  return (
    <Fragment>
      <li className="onhover-dropdown">
        <div className="d-flex align-items-center">
          <img
            className="align-self-center pull-right img-30 rounded-circle blur-up lazyloaded"
            src={man}
            alt="header-user"
          />
          {/*<div className="dotted-animation">*/}
          {/*  <span className="animate-circle"></span>*/}
          {/*  <span className="main-circle"></span>*/}
          {/*</div>*/}
        </div>
        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
          {/*<li>*/}
          {/*  <a href="#javascript">*/}
          {/*    <User />*/}
          {/*    Edit Profile*/}
          {/*  </a>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <a href="#javascript">*/}
          {/*    <Mail />*/}
          {/*    Inbox*/}
          {/*  </a>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <a href="#javascript">*/}
          {/*    <Lock />*/}
          {/*    Lock Screen*/}
          {/*  </a>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <a href="#javascript">*/}
          {/*    <Settings />*/}
          {/*    Settings*/}
          {/*  </a>*/}
          {/*</li>*/}
          <li>
            <a onClick={handleLogout} href="admin/login">
              <LogOut /> Log out
            </a>
          </li>
        </ul>
      </li>
    </Fragment>
  );
};

export default UserMenu;
