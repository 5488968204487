import React, { useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Label, Row } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import TbInput from '../../../../components/common/ui/form/TbInput';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import PayUrlList from './PayUrlList';
import useApiError from '../../../../hooks/useApiError';
import { useMutation } from 'react-query';
import { createPayUrl, ReqCratePayUrl } from '../../../../api/paymentAPI';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import TbModal from '../../../../components/common/ui/TbModal';

const PayUrlUniversal = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const createPayUrlMutation = useMutation(createPayUrl);
  const [isLoading, setIsLoading] = useState(false);
  const [refetchPayments, setRefetchPayments] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [resultPayUrl, setResultPayUrl] = useState<string | null>();

  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const now = new Date();

  // Form
  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ReqCratePayUrl>({
    defaultValues: {},
  });

  /**
   * 폼 서브밋
   * */
  const onSubmit = async (data: ReqCratePayUrl) => {
    try {
      setIsLoading(true);
      const resultData: any = await createPayUrlMutation.mutateAsync(data);
      if (resultData) {
        setRefetchPayments(!refetchPayments);
        setResultPayUrl(resultData.paymentUrl);
        setIsModalOpen(true);
      }
      reset({
        amount: null,
        title: null,
        buyerName: null,
        buyerTel: null,
        buyerEmail: null,
        expiredAt: null,
      });
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * 클립보드 저장
   * */
  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      alert('결제 URL이 복사되었습니다.');
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  // 초기화.
  useDidMountEffect(() => {
    if (!isModalOpen) {
      setResultPayUrl(null);
    }
  }, [isModalOpen]);

  return (
    <>
      <TbModal
        isOpen={isModalOpen}
        isShowFooter={false}
        headerTitle={'로고 결제 링크 생성 완료'}
        toggle={() => setIsModalOpen(!isModalOpen)}
      >
        결제 URL : <br />
        <a href={resultPayUrl ? resultPayUrl : '#'} target={'_blank'} className={'h5 text-primary'} rel="noreferrer">
          {resultPayUrl}
        </a>
        <br />
        <br />
        <div className={'text-center'}>
          <Button
            onClick={() => {
              if (resultPayUrl) {
                copyToClipboard(resultPayUrl);
              }
              setIsModalOpen(false);
            }}
            color="primary"
          >
            링크 복사
          </Button>
        </div>
      </TbModal>
      <Card>
        <CardHeader>
          <h5>다용도 결제 URL 생성</h5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={4}>
              <p>
                다양한 용도로 사용 가능한 결제 링크를 생성합니다.
                <br />
                <span className={'text-danger'}>(비회원도 결제 가능)</span>
              </p>
              <br />

              <form onSubmit={handleSubmit(onSubmit)}>
                <TbInput
                  label={'상품명'}
                  name={'title'}
                  type="text"
                  horizontal={true}
                  register={register}
                  errors={errors}
                  validation={{ required: true }}
                />
                <TbInput
                  label={'금액'}
                  name={'amount'}
                  type="number"
                  horizontal={true}
                  register={register}
                  errors={errors}
                  validation={{ required: true }}
                />
                <TbInput
                  label={'구매자 이름'}
                  name={'buyerName'}
                  type="text"
                  horizontal={true}
                  register={register}
                  errors={errors}
                />
                <TbInput
                  label={'구매자 연락처'}
                  name={'buyerTel'}
                  type="text"
                  horizontal={true}
                  register={register}
                  errors={errors}
                  validation={{ required: true }}
                />
                <TbInput
                  label={'구매자 이메일'}
                  name={'buyerEmail'}
                  type="text"
                  horizontal={true}
                  register={register}
                  errors={errors}
                />
                <FormGroup className={'d-flex'}>
                  <Label style={{ flexShrink: 0, flexBasis: 120 }}>
                    링크 만료일<span className={'text-danger'}>(필수)</span>
                  </Label>
                  <div className={'w-100'}>
                    <Controller
                      control={control}
                      name={'expiredAt'}
                      rules={{
                        required: '링크 만료일을 설정하세요.',
                      }}
                      render={({ field }) => (
                        <DatePicker
                          isClearable
                          className={`datepicker-here form-control ${errors.expiredAt && 'is-invalid'}`}
                          selected={
                            field.value && moment.unix(Number(field.value)).isValid()
                              ? moment.unix(Number(field.value)).toDate()
                              : null
                          }
                          showTimeSelect
                          minDate={new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0)}
                          minTime={
                            startDate && startDate.toDateString() === now.toDateString()
                              ? now // 오늘인 경우 현재 시간 이후로만 설정
                              : new Date(0, 0, 0, 0, 0) // 오늘이 아니면 최소 시간은 00:00
                          }
                          maxTime={
                            startDate && startDate.toDateString() === now.toDateString()
                              ? new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59)
                              : new Date(0, 0, 0, 23, 59) // 미래 날짜는 23:59까지 선택 가능
                          }
                          dateFormat={'yyyy-MM-dd hh:mm aa'}
                          onChange={(date: Date | null) => {
                            setStartDate(date);
                            setValue('expiredAt', moment(date).unix().toString() || '');
                          }}
                        />
                      )}
                    ></Controller>
                    {/* 에러 메시지 표시 */}
                    {errors.expiredAt && <div className={'text-danger mt-1'}>{errors.expiredAt.message}</div>}
                  </div>
                </FormGroup>
                <br />
                <div className={'text-end'}>
                  <Button color="primary" type="submit" disabled={isLoading}>
                    결제 URL 생성하기
                  </Button>
                </div>
              </form>
            </Col>
            <Col md={8}>
              <PayUrlList refetchTrigger={refetchPayments} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default React.memo(PayUrlUniversal);
