export function formatPrice(amount: number): string {
  if (amount > 0) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return '';
}

export const formatStartDate = (date: Date | null): string | null => {
  if (!date) return null;

  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);

  return `${year}-${month}-${day} 00:00:00`;
};

export const formatEndDate = (date: Date | null): string | null => {
  if (!date) return null;

  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);

  return `${year}-${month}-${day} 23:23:59`;
};
