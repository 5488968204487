import React from 'react';

export const paymentColumns = [
  {
    name: 'PID(주문 ID)',
    selector: (row: any) => row.pid,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '150px',
  },
  {
    name: '결제 내용',
    // selector: (row: any) => row.title,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
    cell: (row: any) => (
      <div>
        <div>{row.title}</div>
      </div>
    ),
  },
  {
    name: '결제 금액',
    selector: (row: any) => row.amount,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '80px',
  },
  {
    name: '결제 상태',
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '80px',
    cell: (row: any) => (
      <div>
        {row.orderStatus === 'INIT' && <span className={'btn btn-xs btn-info'}>결제 대기</span>}
        {row.orderStatus === 'PAID' && <span className={'btn btn-xs btn-success'}>결제 완료</span>}
        {row.orderStatus === 'FAILED' && <span className={'btn btn-xs btn-danger'}>결제 실패</span>}
        {row.orderStatus === 'REFUND' && <span className={'btn btn-xs btn-warning'}>환불 완료</span>}
      </div>
    ),
  },
];
