import React, { Fragment } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Col, Container, Row } from 'reactstrap';
import PayUrlLogo from './components/PayUrlLogo';

const PayUrlLogoPage = () => {
  return (
    <Fragment>
      <Breadcrumb parent="도구 모음" title="결제 URL">
        <div className="mb-0">
          {/*<Button color="primary" className="me-3" type="submit">*/}
          {/*  저장*/}
          {/*</Button>*/}
        </div>
      </Breadcrumb>
      <Container fluid={true}>
        <Row>
          <Col sm="5">
            <PayUrlLogo />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default React.memo(PayUrlLogoPage);
