import APIClient from './APIClient';
import { PageInfo } from './types';

// Get
export interface ReqLogoQuestList extends PageInfo {
  id?: string;
  name?: string;
  email?: string;
  userName?: string;
  // idBrand?: string | undefined;
  phoneNumber?: string;
}

export interface ReqConnectBrandId {
  idLogoQuest: number | null;
  idWorkflow: number | null;
}

// Logo Quest Page Info
export const getLogoQuestPage = async (params: ReqLogoQuestList) => {
  const response = await APIClient.get('/v2/api/quest/logo/page', {
    params,
  });
  return response.data;
};

// Logo Quest 문의 조회
export const getLogoQuest = async (id: string) => {
  const response = await APIClient.get(`/v2/api/quest/logo/${id}`);
  return response.data;
};

export const connectBrandId = async (reqBody: ReqConnectBrandId) => {
  const response = await APIClient.patch(`/v2/api/quest/logo/connect-workflow`, reqBody);
  return response.data;
};
