import { PageInfo } from './types';
import APIClient from './APIClient';

export interface ReqSubscriptionPage extends PageInfo {
  type?: 'FREE' | 'PAID' | 'ADMIN';
  status?: 'SUBSCRIBE' | 'EXPIRED';
  periodType?: 'MONTHLY' | 'YEARLY';
  idBrand?: number;
}

export interface ReqCreatePayLink {
  idPlan?: string;
  idCreditPlan?: string;
  idSubscription?: string;
  expiredDate?: Date | null | undefined;
  periodType?: string;
}

export interface ReqSubscribeCancel {
  canceled: boolean;
  cancelDate: string; // yyyy-MM-dd HH:mm:ss
}

export interface ReqUpdateSubscribe {
  idPlan: number;
  idCreditPlan?: number | null;
  type: 'FREE' | 'PAID' | 'ADMIN';
  periodType: 'MONTHLY' | 'YEARLY';
  periodStartDate: string;
  periodEndDate: string;
  startDate?: string;
  endDate?: string;
  // 구매 내역
  title?: string;
  amount?: number;
  paymentDate?: string;
}

/** 구독 **/
// 구독 페이징
export const getSubscriptionPage = async (params: ReqSubscriptionPage) => {
  const response = await APIClient.get(`/v2/api/subscription/page`, {
    params,
  });
  return response.data;
};

// 구독 상세
export const getSubscription = async (id: string) => {
  const response = await APIClient.get(`/v2/api/subscription/${id}`);
  return response.data;
};

// 결제링크 조회
export const getPayLink = async (idSubscription: string) => {
  const response = await APIClient.get(`/v2/api/subscription/payLink`, {
    params: { idSubscription },
  });
  return response.data;
};

/**
 * 구독 설정.
 * */
export const startSubscribe = async (idSubscription: number, body: ReqUpdateSubscribe) => {
  await APIClient.patch(`/v2/api/subscription/${idSubscription}`, body);
};

/**
 * 구독 초기화(무료 계정 전환)
 * */
export const resetSubscribe = async (idSubscription: number) => {
  await APIClient.put(`/v2/api/subscription/reset/${idSubscription}`);
};

/**
 * 구독 만료 설정.
 * */
export const expireSubscribe = async (idSubscription: number) => {
  await APIClient.patch(`/v2/api/subscription/expire/${idSubscription}`);
};

/**
 * 구독 취소 설정.
 * */
export const cancelSubscribe = async (idSubscription: number, body: ReqSubscribeCancel) => {
  await APIClient.patch(`/v2/api/subscription/cancel/${idSubscription}`, body);
};

export const deletePayLink = async (idPayLink: string) => {
  await APIClient.delete(`/v2/api/subscription/payLink/${idPayLink}`);
};

export const createPayLink = async (payLink: ReqCreatePayLink) => {
  await APIClient.post(`/v2/api/subscription/payLink`, payLink);
};
