import { TBFormGroup, TBLabel } from '../../styledComponents';
import React, { ReactElement } from 'react';

interface DefaultProps {
  label?: string;
  message?: string | ReactElement;
  id?: string;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  min: number;
  max: number;
  step: number;
  disabled?: boolean;
  horizontal?: boolean;
  register: any;
  errors: any;
  validation?: any;
}
const TBInputRange = ({
  label = '',
  message = '',
  name = '',
  id = '',
  min = 0,
  max = 10,
  step = 1,
  disabled = false,
  horizontal = false,
  register = undefined,
  errors = {},
  validation = undefined,
  onChange = undefined,
}: DefaultProps) => {
  return (
    <TBFormGroup horizontal={horizontal.toString()}>
      {label && (
        <TBLabel horizontal={horizontal.toString()}>
          {label}
          {validation?.required && <span className={'text-danger'}>(필수)</span>}
        </TBLabel>
      )}
      <div className={'w-100'}>
        <input
          className={`form-range ${errors[name] ? 'is-invalid' : ''}`}
          id={id}
          name={name}
          type={'range'}
          min={min}
          max={max}
          step={step}
          {...register(name, {
            ...validation,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              if (onChange) onChange(e);
            },
          })}
          autoComplete="true"
          disabled={disabled}
        />
        {errors[name] && (
          <div className="text-danger mt-1">
            {errors[name]?.message ? errors[name].message : `${label}을/를 입력하세요.`}
          </div>
        )}
        {message && <div className={'mt-1'}>{message}</div>}
      </div>
    </TBFormGroup>
  );
};
export default React.memo(TBInputRange);
