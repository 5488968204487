import React, { Fragment, useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { Button, Col, Container, Row } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { getWorkflowDetail, getWorkflowEnum } from '../../api/workflowAPI';
import useApiError from '../../hooks/useApiError';
import Breadcrumb from '../../components/common/breadcrumb';
import moment from 'moment';
import ManageSubscription from '../subscription/components/ManageSubscription';
import ManageCredit from '../subscription/components/ManageCredit';
import ManageTask from '../subscription/components/ManageTask';
import WorkflowSearch from './components/workflowSearch';
import TbModal from '../../components/common/ui/TbModal';
import ManageLogoQuest from './components/ManageLogoQuest';
import ManagePayment from '../subscription/components/ManagePayment';
import ManageBrand from '../subscription/components/ManageBrand';

const ReadWorkflow: React.FC = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const { id: idWorkflow } = useParams<{ id: string }>();
  const [isConnectBrandModal, setIsConnectBrandModal] = useState(false);
  const [refetchBrandInfo, setRefetchBrandInfo] = useState(false);

  // Update 일떄 초기 데이터 로드
  const { data: defaultData, refetch } = useQuery(
    ['getWorkflowDetail', idWorkflow],
    () => getWorkflowDetail(idWorkflow as string),
    {
      onError: (error) => {
        handleApiError(error);
      },
    },
  );
  // =================== 공통 항목 끝 ==================  //

  // Enum API
  const { data: enumType } = useQuery(['enumData'], getWorkflowEnum, {
    onError: (error) => {
      handleApiError(error);
    },
  });

  const onCompleteConnect = useCallback(() => {
    setIsConnectBrandModal(false);
    refetch();
  }, []);

  /**
   * 크레딧 변동 콜백
   * */
  const handleOnUpdateCredit = useCallback(() => {
    // 브랜드 정보 다시 로드(보유 크레딧)
    setRefetchBrandInfo(!refetchBrandInfo);
  }, []);

  // R ea d
  return (
    <Fragment>
      {/** 브랜드 연결 모달 */}
      <TbModal
        isOpen={isConnectBrandModal}
        headerTitle={'브랜드 연결'}
        isShowFooter={false}
        toggle={() => setIsConnectBrandModal(!isConnectBrandModal)}
      >
        {isConnectBrandModal && (
          <WorkflowSearch
            idWorkflow={Number(idWorkflow)}
            label={'브랜드명으로 검색하세요.'}
            onComplete={onCompleteConnect}
          />
        )}
      </TbModal>
      <Breadcrumb parent="고객관리" title="상세 정보">
        <div className="mb-0">
          <Button color="danger" className="me-3" onClick={() => setIsConnectBrandModal(true)}>
            브랜드 연결하기
          </Button>
          <Link to={`/admin/workflow/update/${idWorkflow}`} className="me-3">
            <Button color="secondary">수정</Button>
          </Link>
          <Link to={`/admin/workflow/list`}>
            <Button color="primary">목록</Button>
          </Link>
        </div>
      </Breadcrumb>
      <Container fluid={true}>
        <Row>
          <Col lg={4}>
            {/****** 고객 정보 ******/}
            <div className="card">
              <div className={'card-header'}>
                <h5>고객 정보</h5>
              </div>
              <div className={'card-body'}>
                <Row className="details">
                  <Col xs="6" className={'detail-item'}>
                    브랜드명 / 기업명
                    <br />
                    <span>
                      {defaultData?.brandName ? defaultData.brandName : '-'}
                      &nbsp;/&nbsp;
                      {defaultData?.businessName ? defaultData.businessName : '-'}
                    </span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    담당자 / 연락처
                    <br />
                    <span>
                      {defaultData?.userName} / {defaultData?.phoneNumber}
                    </span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    담당 매니저
                    <br />
                    <span>{enumType?.managerList[defaultData?.manager]}</span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    우선 순위
                    <br />
                    {defaultData?.priority === 'PRIORITY_0' && (
                      <div className={'btn btn-xs btn-danger'}>{enumType?.priorityList[defaultData?.priority]}</div>
                    )}
                    {defaultData?.priority === 'PRIORITY_1' && (
                      <div className={'btn btn-xs btn-warning'}>{enumType?.priorityList[defaultData?.priority]}</div>
                    )}
                    {defaultData?.priority === 'PRIORITY_2' && (
                      <div className={'btn btn-xs btn-info'}>{enumType?.priorityList[defaultData?.priority]}</div>
                    )}
                    {defaultData?.priority === 'PRIORITY_3' && (
                      <div className={'btn btn-xs btn-success'}>{enumType?.priorityList[defaultData?.priority]}</div>
                    )}
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    온보딩 상태
                    <br />
                    <span>{enumType?.onBoardingList[defaultData?.onBoarding] || '-'}</span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    디자인 상태
                    <br />
                    <span>{enumType?.designList[defaultData?.design] || '-'}</span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    플랫폼
                    <br />
                    <span>{enumType?.platformList[defaultData?.platform] || '-'}</span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    요금제
                    <br />
                    <span>
                      {defaultData?.plan
                        ? `${defaultData?.plan?.title} (ID.${defaultData?.plan?.id}, Lv.${defaultData?.plan?.level})`
                        : '-'}
                    </span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    계약 기간
                    <br />
                    <span>
                      {defaultData?.startDate ? moment(defaultData?.startDate).format('YYYY/MM/DD') : ''}
                      &nbsp;~&nbsp;
                      {defaultData?.endDate ? moment(defaultData?.endDate).format('YYYY/MM/DD') : ''}
                    </span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    견적
                    <br />
                    <span>
                      {defaultData?.price ? `${defaultData?.price?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원` : '-'}
                    </span>
                  </Col>
                  <Col xs="12" className={'detail-item'}>
                    메모
                    <br />
                    <span>{defaultData?.memo ? defaultData.memo : '-'}</span>
                  </Col>
                </Row>
              </div>
            </div>

            {/****** 브랜드/유저 정보 ******/}
            <ManageBrand idBrand={defaultData?.brand?.id} refetchBrandInfo={refetchBrandInfo} />

            {/****** 구독 정보 ******/}
            <ManageSubscription subscription={defaultData?.subscription} />

            {/****** 로고퀘스트 정보 ******/}
            <ManageLogoQuest idBrand={defaultData?.brand?.id} brandQuestData={defaultData?.brandQuest} />
          </Col>

          <Col lg={8}>
            {/****** 크레딧 관리 ******/}
            <ManageCredit
              idBrand={defaultData?.brand?.id}
              creditBrand={defaultData?.brand?.credit}
              onUpdateCredit={handleOnUpdateCredit}
            />

            {/****** 태스크 관리 ******/}
            <ManageTask idBrand={defaultData?.brand?.id} />

            {/****** 결제 내역 ******/}
            <ManagePayment idSubscription={defaultData?.subscription?.id} status={defaultData?.subscription?.status} />
          </Col>
        </Row>
        <br />
      </Container>
    </Fragment>
  );
};

export default ReadWorkflow;
