import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 footer-copyright">
            <p className="mb-0">{'Copyright 2024 © Tidy-B All rights reserved.'}</p>
          </div>
          <div className="col-md-6">
            <p className="pull-right mb-0">
              Tidy-B
              {/*{'Hand crafted & made with'}*/}
              {/*<i className="fa fa-heart"></i>*/}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
