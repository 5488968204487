// 메뉴
import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Settings } from 'react-feather';
import { ReqWorkflow, ReqWorkflowList } from '../../../api/workflowAPI';
import WorkflowColumnInput, { DefaultInputStyle } from '../components/WFColInputText';
import WFColInputSelect from '../components/WFColInputSelect';
import WFColInputCheckBox from '../components/WFInputCheckBox';
import { enumToSelect } from '../../../utils/enumUtils';

export const workflowLogoColumns = (
  enumType: any,
  reqParam: ReqWorkflowList,
  totalPrice: number,
  handleToggleMenu: (id: number) => void,
  handleDeleteItem: (id: string) => void,
  handleOpenDatePicker: (id: number, fieldName: string, currentValue: Date) => void,
  handleOpenTextarea: (id: number, fieldName: string, currentValue: string, readonly?: boolean) => void,
  handleChangeInput: (reqBody: ReqWorkflow) => void,
  handleChnageFilter: (filterName: string, value: string) => void,
) => [
  {
    name: 'Fn',
    cell: (row: any) => (
      <Dropdown isOpen={row.toggleMenu} toggle={() => handleToggleMenu(row.id)}>
        <DropdownToggle size={'xs'} color="dark" outline={true}>
          <Settings size={8} />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem href={`/admin/workflow/read/${row.id}`}>{'자세히 보기'}</DropdownItem>
          <DropdownItem onClick={() => handleDeleteItem?.(row.id)}>{'삭제'}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    ),
    center: true,
    wrap: true,
    width: '26px',
  },
  {
    name: (
      <div className={'text-center w-100'}>
        <div>등록일</div>
        <DefaultHeaderSelect
          value={reqParam.sort || ''}
          onChange={(e) => {
            handleChnageFilter('sort', e.target.value);
          }}
        >
          <option value={'endDate,asc'} disabled={true}>
            [--]
          </option>
          <option value={'startDate,desc'}>[최신순]</option>
          <option value={'startDate,asc'}>[오래된순]</option>
        </DefaultHeaderSelect>
      </div>
    ),
    cell: (row: any) => (
      <DefaultText onClick={() => handleOpenDatePicker(row.id, 'startDate', row.startDate)}>
        {row.startDate ? moment(row.startDate).format('YY/MM/DD') : ''}
      </DefaultText>
    ),
    center: true,
    wrap: true,
    width: '64px',
  },
  {
    name: '브랜드명',
    cell: (row: any) => (
      <WorkflowColumnInput
        value={row.brandName}
        onChange={(newValue) => {
          console.log('newValue', newValue);
          handleChangeInput({
            brandName: newValue || '',
            id: row.id,
          });
        }}
        isShowCopy={true}
      />
    ),
    center: true,
    wrap: true,
    width: '130px',
  },
  {
    name: '고객명',
    cell: (row: any) => (
      <WorkflowColumnInput
        value={row.userName}
        onChange={(newValue) => {
          handleChangeInput({
            userName: newValue || '',
            id: row.id,
          });
        }}
        isShowCopy={true}
      />
    ),
    center: true,
    wrap: true,
    width: '70px',
  },
  {
    name: '연결 계정',
    cell: (row: any) => (
      <DefaultText
        title={row.connected ? row.connectEmail : 'disconnected..'}
        onClick={() => handleOpenTextarea(row.id, 'connectEmail', row.connectEmail, true)}
      >
        <ConnectIcon style={{ backgroundColor: row.connected ? '#37d310' : '#fd2424' }} />
        {row.connected ? row.connectEmail : 'disconnected..'}
      </DefaultText>
    ),
    center: true,
    wrap: false,
    width: '60px',
  },
  {
    name: (
      <div className={'text-center w-100'}>
        <div>시안제공일</div>
        <DefaultHeaderSelect
          value={reqParam.sort || ''}
          onChange={(e) => {
            handleChnageFilter('sort', e.target.value);
          }}
        >
          {/*<option value={''}>[전체]</option>*/}
          <option value={'startDate,desc'}>[--]</option>
          <option value={'endDate,asc'}>[마감일순]</option>
        </DefaultHeaderSelect>
      </div>
    ),
    cell: (row: any) => (
      <DefaultText onClick={() => handleOpenDatePicker(row.id, 'endDate', row.endDate)}>
        {row.endDate ? moment(row.endDate).format('YY/MM/DD') : ''}
      </DefaultText>
    ),
    center: true,
    wrap: true,
    width: '64px',
  },
  {
    name: (
      <div className={'text-center w-100'}>
        <div>프로세스</div>
        <DefaultHeaderSelect
          value={reqParam.process}
          onChange={(e) => {
            handleChnageFilter('process', e.target.value);
          }}
        >
          <option value={''}>[전체]</option>
          {enumType?.processList &&
            enumToSelect(enumType.processList).map((item: any) => (
              <option key={item.value} value={item.value}>
                [{item.label}]
              </option>
            ))}
        </DefaultHeaderSelect>
      </div>
    ),
    cell: (row: any) => (
      <WFColInputSelect
        menu={enumType?.processList ? enumToSelect(enumType.processList) : []}
        value={row.process}
        onChange={(newValue) => {
          handleChangeInput({
            process: newValue,
            id: row.id,
          });
        }}
        optionStyle={{
          ...(row.process === 'PROCESS_0' && { backgroundColor: '#fabebe' }),
          ...(row.process === 'PROCESS_1' && { backgroundColor: '#eee' }),
          ...(row.process === 'PROCESS_2' && { backgroundColor: '#eee' }),
          ...(row.process === 'PROCESS_3' && { backgroundColor: '#dcffd4', color: '#116b02' }),
          ...(row.process === 'PROCESS_4' && { backgroundColor: '#ffe29d' }),
          ...(row.process === 'PROCESS_5' && { backgroundColor: '#4d4d4d', color: '#fff' }),
          ...(row.process === 'PROCESS_6' && { backgroundColor: '#eee' }),
          ...(row.process === 'PROCESS_7' && { backgroundColor: '#941d1d', color: '#fff' }),
          ...(row.process === 'PROCESS_8' && { backgroundColor: '#4d4d4d', color: '#e8ff00' }),
          ...(row.process === 'PROCESS_9' && { backgroundColor: '#eee' }),
        }}
      />
    ),
    center: true,
    wrap: true,
    width: '75px',
  },
  {
    name: (
      <div className={'text-center w-100'}>
        <div>명함</div>
        <DefaultHeaderSelect
          value={reqParam.businessCard}
          onChange={(e) => {
            handleChnageFilter('businessCard', e.target.value);
          }}
        >
          <option value={''}>[전체]</option>
          {enumType?.businessCardList &&
            enumToSelect(enumType.businessCardList).map((item: any) => (
              <option key={item.value} value={item.value}>
                [{item.label}]
              </option>
            ))}
        </DefaultHeaderSelect>
      </div>
    ),
    cell: (row: any) => (
      <WFColInputSelect
        menu={enumType?.businessCardList ? enumToSelect(enumType.businessCardList) : []}
        value={row.businessCard}
        onChange={(newValue) => {
          if (newValue) {
            handleChangeInput({
              businessCard: newValue,
              id: row.id,
            });
          }
        }}
      />
    ),
    center: true,
    wrap: true,
    width: '80px',
  },
  {
    name: (
      <div className={'text-center w-100'}>
        <div>디지이너</div>
        <DefaultHeaderSelect
          value={reqParam.manager}
          onChange={(e) => {
            handleChnageFilter('manager', e.target.value);
          }}
        >
          <option value={''}>[전체]</option>
          {enumType?.managerList &&
            enumToSelect(enumType.managerList).map((item: any) => (
              <option key={item.value} value={item.value}>
                [{item.label}]
              </option>
            ))}
        </DefaultHeaderSelect>
      </div>
    ),
    cell: (row: any) => (
      <WFColInputSelect
        menu={enumType?.managerList ? enumToSelect(enumType.managerList) : []}
        value={row.manager}
        onChange={(newValue) => {
          if (newValue) {
            handleChangeInput({
              manager: newValue,
              id: row.id,
            });
          }
        }}
      />
    ),
    center: true,
    wrap: true,
    width: '58px',
  },
  {
    // name: '유입경로',
    name: (
      <div className={'text-center w-100'}>
        <div>유입경로</div>
        <DefaultHeaderSelect
          value={reqParam.platform}
          onChange={(e) => {
            handleChnageFilter('platform', e.target.value);
          }}
        >
          <option value={''}>[전체]</option>
          {enumType?.platformList &&
            enumToSelect(enumType.platformList).map((item: any) => (
              <option key={item.value} value={item.value}>
                [{item.label}]
              </option>
            ))}
        </DefaultHeaderSelect>
      </div>
    ),
    cell: (row: any) => (
      <WFColInputSelect
        menu={enumType?.platformList ? enumToSelect(enumType.platformList) : []}
        value={row.platform}
        onChange={(newValue) => {
          if (newValue) {
            handleChangeInput({
              platform: newValue,
              id: row.id,
            });
          }
        }}
      />
    ),
    center: true,
    wrap: true,
    width: '84px',
  },
  {
    // name: '결제방식',
    name: (
      <div className={'text-center w-100'}>
        <div>결제방식</div>
        <DefaultHeaderSelect
          value={reqParam.payMethod}
          onChange={(e) => {
            handleChnageFilter('payMethod', e.target.value);
          }}
        >
          <option value={''}>[전체]</option>
          {enumType?.payMethodList &&
            enumToSelect(enumType.payMethodList).map((item: any) => (
              <option key={item.value} value={item.value}>
                [{item.label}]
              </option>
            ))}
        </DefaultHeaderSelect>
      </div>
    ),
    cell: (row: any) => (
      <WFColInputSelect
        menu={enumType?.payMethodList ? enumToSelect(enumType.payMethodList) : []}
        value={row.payMethod}
        onChange={(newValue) => {
          if (newValue) {
            handleChangeInput({
              payMethod: newValue,
              id: row.id,
            });
          }
        }}
        optionStyle={{
          ...(row.payMethod === 'PAY_METHOD_0' && { backgroundColor: '#dcffd4', color: '#116b02' }),
          ...(row.payMethod === 'PAY_METHOD_4' && { backgroundColor: '#b4c4ff', color: '#001985' }),
          ...(row.payMethod === 'PAY_METHOD_5' && { backgroundColor: '#ffe29d', color: '#503700' }),
        }}
      />
    ),
    center: true,
    wrap: true,
    width: '78px',
  },
  {
    name: (
      <div className={'text-center'}>
        결제금액
        <br />
        함계 : <span style={{ fontWeight: 500, color: '#e8ff00' }}>{totalPrice.toLocaleString()}</span>
      </div>
    ),
    cell: (row: any) => (
      <WorkflowColumnInput
        style={{ textAlign: 'right' }}
        value={row.price}
        onChange={(newValue) => {
          handleChangeInput({
            price: newValue || '',
            id: row.id,
          });
        }}
      />
    ),
    center: true,
    wrap: true,
    width: '64px',
  },
  {
    name: '고객 요청사항',
    cell: (row: any) => (
      <DefaultText style={{ textAlign: 'left' }} onClick={() => handleOpenTextarea(row.id, 'request', row.request)}>
        {row.request}
      </DefaultText>
    ),
    center: true,
    wrap: true,
    width: '160px',
  },
  {
    name: '결제/입금/세금계산서 메모',
    cell: (row: any) => (
      <DefaultText
        title={row.memo}
        style={{ textAlign: 'left' }}
        onClick={() => handleOpenTextarea(row.id, 'memo', row.memo)}
      >
        {row.memo}
      </DefaultText>
    ),
    center: true,
    wrap: true,
    width: '160px',
  },
  {
    name: '환불 메모',
    cell: (row: any) => (
      <DefaultText
        title={row.refund}
        style={{ textAlign: 'left' }}
        onClick={() => handleOpenTextarea(row.id, 'refund', row.refund)}
      >
        {row.refund}
      </DefaultText>
    ),
    center: true,
    wrap: true,
    width: '60px',
  },
  {
    name: (
      <span className={'text-center'}>
        세금계산서
        <br />
        수령 이메일
      </span>
    ),
    cell: (row: any) => (
      <DefaultText title={row.email} onClick={() => handleOpenTextarea(row.id, 'email', row.email)}>
        {row.email}
      </DefaultText>
    ),
    center: true,
    wrap: true,
    width: '60px',
  },
  {
    name: <span style={{ letterSpacing: '-1px' }}>입금완료</span>,
    cell: (row: any) => (
      <WFColInputCheckBox
        value={row.paid}
        onChange={(newValue) => {
          handleChangeInput({
            paid: newValue,
            payment: row.payment,
            id: row.id,
          });
        }}
      />
    ),
    center: true,
    wrap: true,
    width: '50px',
  },
  {
    name: (
      <span className={'text-center'} style={{ letterSpacing: '-1.5px' }}>
        계산서/
        <br />
        영수증
        <br />
        발행완료
      </span>
    ),
    cell: (row: any) => (
      <WFColInputCheckBox
        value={row.payment}
        onChange={(newValue) => {
          handleChangeInput({
            paid: row.paid,
            payment: newValue,
            id: row.id,
          });
        }}
      />
    ),
    center: true,
    wrap: true,
    width: '50px',
  },
];

const DefaultText = styled.div`
  width: 100%;
  height: 100%;
  font-size: 13px;
  letter-spacing: -0.7px;
  cursor: pointer;
  text-align: center;
  line-height: 23px;
  //display: flex;
  //justify-content: center;
  //align-items: center;

  white-space: nowrap;
  overflow: hidden; // 너비를 넘어가면 안보이게
  text-overflow: ellipsis; // 글자가 넘어가면 말줄임(...) 표시
  &:hover {
    outline: 2px solid #0b5ed7 !important;
    outline-offset: -1px;
  }
`;
export const DefaultTextInput = styled.input`
  ${DefaultInputStyle}
`;
export const DefaultHeaderSelect = styled.select`
  ${DefaultInputStyle};
  color: #e8ff00;
`;

const ConnectIcon = styled.div`
  width: 8px;
  height: 8px;
  margin-right: 4px;
  border-radius: 50%;
  display: inline-block;
`;
