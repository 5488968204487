import React from 'react';

export const KeywordColumns = [
  {
    name: 'id',
    selector: (row: any) => row.id,
    // sortable: true,
    center: true,
    // wrap: true,
    width: '60px',
  },
  {
    name: '키워드',
    selector: (row: any) => row.nameKoKr,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '노출여부',
    selector: (row: any) => row.active,
    cell: (row: any) => {
      return row.active ? (
        <span className="btn btn-outline-success btn-xs">활성</span>
      ) : (
        <span className="btn btn-outline-warning btn-xs">비활성</span>
      );
    },
    sortable: true,
    center: true,
    wrap: true,
  },
];
