import React, { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Breadcrumb from '../../components/common/breadcrumb';
import TbFilter from '../../components/common/ui/TbFilter';
import useApiError from '../../hooks/useApiError';
import { useQuery } from 'react-query';
import {
  getEditorElementCategoryPage,
  getEditorElementCollectionPage,
  getEditorElementEnum,
  getEditorElementPage,
  ReqEditorElementPage,
} from '../../api/editorElementAPI';
import DataTable from 'react-data-table-component';
import { cellsStyle, headCellsStyle, rowsStyle } from '../../data/customizer/react-data-table-config';
import { editorElementColumns } from './data/editor-element-columns';
import { formatEndDate, formatStartDate } from '../../utils/formatUtils';
import { Col, Row } from 'reactstrap';
import TbSelect from '../../components/common/ui/form/TbSelect';
import { enumToSelect } from '../../utils/enumUtils';

const EditorElementList = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();
  const [elementData, setElementData] = useState<any>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [reqParam, setReqParam] = useState<ReqEditorElementPage>({
    page: 0,
    size: 30,
    query: '',
    // filter
    type: '',
    idCategory: '',
    active: null,
  });

  /**
   * Enum 리스트 조회.
   * */
  const { data: enumList } = useQuery(['getEditorElementEnum'], () => getEditorElementEnum(), {
    select: (data) => {
      return enumToSelect(data.typeList);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 목록 조회.
   * */
  const { isLoading } = useQuery(['getEditorElementPage', { reqParam }], () => getEditorElementPage(reqParam), {
    onSuccess: (data) => {
      setElementData(data?._embedded?.editorElements || []);
      setTotalRows(data?.page?.totalElements || 0);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 카테고리 목록 요청.
   * */
  const { data: resCategories } = useQuery(
    ['getEditorElementCategoryPage'],
    () => getEditorElementCategoryPage({ size: 1000 }),
    {
      select: (data) => {
        return data?._embedded?.categories?.map((item: any) => ({
          label: item.name,
          value: item.idElementCategory,
        }));
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 컬렉션 목록 요청.
   * */
  const { data: resCollections } = useQuery(
    ['getEditorElementCollectionPage'],
    () => getEditorElementCollectionPage({ size: 1000 }),
    {
      select: (data) => {
        return data?._embedded?.collections?.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 페이지 변경 핸들러
   * */
  const handlePageChange = (page: number) => {
    setReqParam({
      ...reqParam,
      page: page - 1,
    });
  };

  /**
   * 한 페이지 당 보여줄 행 수 변경 핸들러
   * */
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParam({
      ...reqParam,
      size: newPerPage,
      page: page - 1,
    });
  };

  /**
   * 행 클릭 핸들러
   * */
  const handleRowClicked = (row: any) => {
    navigate(`/admin/editor-element/update/${row.idElement}`);
  };

  /**
   * 검색 핸들러
   * */
  const onSubmitSearch = (filters: any) => {
    setReqParam({
      ...reqParam,
      query: filters.query,
      startDate: filters.startDate ? formatStartDate(filters.startDate) : null,
      endDate: filters.endDate ? formatEndDate(filters.endDate) : null,
      type: filters.type,
      idCategory: filters.idCategory,
      idCollection: filters.idCollection,
      active: filters.active,
    });
  };

  return (
    <Fragment>
      <Breadcrumb parent="에디터 요소 관리" title="요소">
        <Link className="btn btn-primary" to={`/admin/editor-element/create`}>
          새로 등록
        </Link>
      </Breadcrumb>
      <div className="container-fluid">
        {/* :::::::::: Filter : Start ::::::::::  */}
        <TbFilter onSubmit={onSubmitSearch}>
          {({ register, errors }) => (
            <Row>
              <Col md={6}>
                <Row>
                  <Col sm={2}>
                    <label className="col-form-label">형식</label>
                  </Col>
                  <Col sm={10}>
                    <TbSelect
                      placeholder={'전체'}
                      name="type"
                      options={enumList || []}
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col sm={2}>
                    <label className="col-form-label">카테고리</label>
                  </Col>
                  <Col sm={10}>
                    <TbSelect
                      placeholder={'전체'}
                      name="idCategory"
                      options={resCategories || []}
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col sm={2}>
                    <label className="col-form-label">컬렉션</label>
                  </Col>
                  <Col sm={10}>
                    <TbSelect
                      placeholder={'전체'}
                      name="idCollection"
                      options={resCollections || []}
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col sm={2}>
                    <label className="col-form-label">활성상태</label>
                  </Col>
                  <Col sm={10}>
                    <TbSelect
                      placeholder={'전체'}
                      name="active"
                      options={[
                        { label: '활성', value: 'true' },
                        { label: '비활성', value: 'false' },
                      ]}
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </TbFilter>
        {/* :::::::::: Filter : End ::::::::::  */}

        {/*  :::::::::: List ::::::::::  */}
        <DataTable
          noHeader
          columns={editorElementColumns()}
          data={elementData}
          progressPending={isLoading}
          highlightOnHover
          pointerOnHover
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={30}
          paginationRowsPerPageOptions={[5, 10, 30, 50, 100]}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onRowClicked={handleRowClicked}
          dense={true}
          customStyles={{
            rows: rowsStyle,
            headCells: headCellsStyle,
            cells: cellsStyle,
          }}
        />
      </div>
    </Fragment>
  );
};
export default React.memo(EditorElementList);
