import styled, { css } from 'styled-components';
import { FormGroup, Label } from 'reactstrap';

export const TBFormGroup = styled(FormGroup)`
  ${(props) =>
    props.horizontal === 'true' &&
    css`
      display: flex;
      align-items: center;
    `}
`;

export const TBLabel = styled(Label)`
  ${(props) =>
    props.horizontal === 'true' &&
    css`
      flex-shrink: 0;
      flex-basis: 130px;
      max-width: 130px;
    `}
`;
