import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React from 'react';
import useApiError from '../../../hooks/useApiError';
import { useMutation } from 'react-query';
import { resetSubscribe } from '../../../api/subscriptionAPI';
import { toast } from 'react-toastify';

interface ModalProps {
  isOpen: boolean;
  toggle: () => void;
  onSuccess?: () => void;
  idSubscription: number;
}

const ManageSubscribeCancelModal = ({ isOpen, toggle, onSuccess, idSubscription }: ModalProps) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const putResetSubscribe = useMutation(resetSubscribe);

  /**
   * 구독 초기화 설정 요청.
   * */
  const handleOnSubmit = async () => {
    try {
      await putResetSubscribe.mutateAsync(idSubscription);
      toast.success('구독 상태가 무료 계정으로 초기화 되었습니다.');
      toggle();
      // 성공 콜백
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size={'md'}>
      <ModalHeader>구독 초기화 설정</ModalHeader>
      <ModalBody>
        <p>
          - 계정을 최초 무료 상태로 초기화 합니다.
          <br />- 구독 만료 상태에서 초기화 가능합니다.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          onClick={(e) => {
            e.preventDefault();
            toggle?.();
          }}
        >
          취소
        </Button>
        <Button onClick={handleOnSubmit} color="primary" className="me-3" type={'submit'}>
          초기화
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(ManageSubscribeCancelModal);
