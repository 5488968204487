import React from 'react';

export const paymentColumns = [
  {
    name: 'ID',
    selector: (row: any) => row.id,
    center: true,
    minWidth: '170px',
    cell: (row: any) => (
      <div className={'text-left'}>
        ID.{row.id}
        <br />
        {row.pid}
      </div>
    ),
  },
  {
    name: '결제 내용',
    selector: (row: any) => row.title,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '250px',
  },
  {
    name: '결제 금액',
    selector: (row: any) => row.amount,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '120px',
  },
  {
    name: '상태',
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '150px',
    cell: (row: any) => (
      <div>
        {row.orderStatus === 'INIT' && <span className={'btn btn-xs btn-info'}>대기중</span>}
        {row.orderStatus === 'PAID' && <span className={'btn btn-xs btn-success'}>결제완료</span>}
        {row.orderStatus === 'FAILED' && <span className={'btn btn-xs btn-danger'}>결제실패</span>}
        {row.orderStatus === 'REFUND' && <span className={'btn btn-xs btn-warning'}>환불완료</span>}
        <br />
        {row.failedMsg && <span className={'text-danger'}>{row.failedMsg}</span>}
      </div>
    ),
  },
  {
    name: '결제 수단',
    selector: (row: any) => row.type,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '60px',
  },
  {
    name: '결제일',
    selector: (row: any) => row.paymentDate,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  },
];
