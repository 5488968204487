import React, { useState } from 'react';
import styled from 'styled-components';

interface PropsType {
  value: boolean;
  onChange: (newValue: any) => void;
}
const WFColInputCheckBox = ({ value, onChange }: PropsType) => {
  const [isChecked, setIsChecked] = useState<boolean>(value);
  return (
    <CheckBox
      type={'checkbox'}
      checked={isChecked}
      onChange={(e) => {
        const newValue = e.target.checked;
        if (newValue !== value) {
          setIsChecked(newValue);
          onChange(newValue);
        }
      }}
    />
  );
};

export default React.memo(WFColInputCheckBox);

const CheckBox = styled.input``;
