import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'reactstrap';

interface PropsType {
  value: string;
  onChange: (newValue: any) => void;
  readonly?: boolean;
}
const WFTextArea = ({ value, onChange, readonly }: PropsType) => {
  const [inputValue, setInputValue] = useState<string | number>(value || '');
  return (
    <div>
      <TextArea
        readOnly={!!readonly}
        value={inputValue}
        rows={20}
        onChange={(e) => {
          const newValue = e.target.value;
          setInputValue(newValue);
        }}
      />
      <Button
        style={{ borderRadius: 0 }}
        block={true}
        size={'lg'}
        color={'primary'}
        onClick={() => {
          onChange(inputValue);
        }}
      >
        {readonly ? '확인' : '저장하기'}
      </Button>
    </div>
  );
};

export default React.memo(WFTextArea);

const TextArea = styled.textarea`
  display: block;
  width: 100%;
  padding: 16px;
`;
