import React, { CSSProperties, ReactElement } from 'react';
import styled from 'styled-components';
import { TBFormGroup, TBLabel } from '../../styledComponents';

export interface TbSelectItem {
  label?: string;
  value?: string | number;
}
interface TbSelectProps {
  label?: string;
  message?: string | ReactElement;
  row?: boolean;
  id?: string;
  name?: string;
  readonly?: boolean;
  placeholder?: string;
  isPlaceholder?: boolean;
  disabled?: boolean;
  horizontal?: boolean;
  options: TbSelectItem[];
  value?: any;
  register?: any;
  validation?: any;
  errors?: any;
  inputClassName?: string;
  inputStyle?: CSSProperties;
}

const TbSelect: React.FC<TbSelectProps> = ({
  placeholder = '',
  name = '',
  message = '',
  readonly = false,
  label = '',
  id = '',
  options = [],
  value = undefined,
  isPlaceholder = true,
  disabled = false,
  horizontal = false,
  register = undefined,
  validation = undefined,
  errors = {},
  inputClassName = '',
  inputStyle,
}) => {
  return (
    <TBFormGroup horizontal={horizontal.toString()}>
      {!label ? null : (
        <TBLabel horizontal={horizontal.toString()}>
          {label}
          {validation?.required && <span className={'text-danger'}>(필수)</span>}
        </TBLabel>
      )}
      <div className={'w-100'}>
        <div className="position-relative">
          <SelectLayout
            disabled={disabled}
            style={inputStyle}
            className={`form-control digits position-relative ${inputClassName || ''} ${
              errors?.[name] ? 'is-invalid' : ''
            } ${readonly ? 'readonly' : ''}`}
            defaultValue=""
            id={id}
            name={name}
            value={value}
            {...register?.(name, validation)}
          >
            {isPlaceholder ? <option value={''}>{placeholder ? placeholder : '(선택 안함)'}</option> : ''}
            {options?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </SelectLayout>
          <SelectIcon className="icon-angle-down" />
        </div>
        {errors && errors[name] && (
          <div className="text-danger mt-1">{errors[name].message || `${label}을/를 선택하세요.`}</div>
        )}
        {message && <div className={'mt-1'}>{message}</div>}
      </div>
    </TBFormGroup>
  );
};

export default React.memo(TbSelect);

const SelectIcon = styled.div`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 0;
`;

const SelectLayout = styled.select`
  &:disabled {
    color: #ced4da;
  }

  &.readonly {
    pointer-events: none !important;
    background-color: #eee;
  }
`;
