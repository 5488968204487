import React, { Fragment, useState } from 'react';
import { Col, Collapse, Row } from 'reactstrap';
import { FieldErrors, useForm, UseFormRegister } from 'react-hook-form';
import TbInput from './form/TbInput';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import useDidMountEffect from '../../../hooks/useDidMountEffect';

interface FilterProps {
  children?: (props: { register: UseFormRegister<any>; errors: FieldErrors<any>; setValue: any }) => React.ReactNode;
  onSubmit: (value: any) => void;
  defaultValues?: any;
}

const TbFilter: React.FC<FilterProps> = ({ children, onSubmit = () => {}, defaultValues }) => {
  const [isFilter, setIsFilter] = useState(true);
  // Date states
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    ...(defaultValues ? { defaultValues: defaultValues } : {}),
  });

  // 기본 설정값 셋팅
  useDidMountEffect(() => {
    if (defaultValues) {
      for (const key in defaultValues) {
        setValue(key, defaultValues[key]);
      }
    }
  }, [defaultValues]);

  const handleFormSubmit = (data: any) => {
    onSubmit({
      ...data,
      startDate,
      endDate,
    });
  };

  return (
    <Fragment>
      <TbFilterLayout className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header">
                <button
                  className="btn btn-link ps-0"
                  data-toggle="collapse"
                  onClick={() => setIsFilter(!isFilter)}
                  data-target="#collapseicon"
                  aria-expanded={isFilter}
                  aria-controls="collapseicon"
                >
                  <h5 className="mb-0">검색 필터</h5>
                </button>
              </div>
              <Collapse isOpen={isFilter}>
                <form onSubmit={handleSubmit(handleFormSubmit)} className="card-body filter-cards-view animate-chk ">
                  <Row className="align-items-center ">
                    {/* :::::::::: 검색  ::::::::: */}
                    <Col md={6}>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">검색</label>
                        </Col>
                        <Col sm={10}>
                          <TbInput
                            id={'query'}
                            name={'query'}
                            type={'text'}
                            placeholder={'검색어를 입력하세요.'}
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </Col>
                    {/* :::::::::: 날짜  :::::::::  */}
                    <Col md={6}>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">등록일</label>
                        </Col>
                        <Col sm={10}>
                          <div className={'d-flex align-items-center'}>
                            <div className="w-100 mb-3">
                              <DatePicker
                                className="datepicker-here form-control"
                                selected={startDate}
                                dateFormat={'yyyy-MM-dd'}
                                onChange={(date: Date | null) => setStartDate(date)}
                              />
                            </div>
                            <div className="text-center p-l-10 p-r-10">~</div>
                            <div className="w-100 mb-3">
                              <DatePicker
                                className="datepicker-here form-control"
                                selected={endDate}
                                dateFormat={'yyyy-MM-dd'}
                                onChange={(date: Date | null) => setEndDate(date)}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* :::::::::: 추가 컴포넌트 ::::::::: */}
                  {children && children({ register, errors, setValue })}
                  <div className={' mt-1'} style={{ margin: '0 -30px', borderBottom: '1px solid #e8ebf2' }}></div>
                  {/* :::::::::: Submit Button :::::::::: */}
                  <div className={'d-flex justify-content-end mt-3'}>
                    <div>
                      <button className="btn btn-primary" type="submit">
                        검색
                      </button>
                    </div>
                  </div>
                  {/* :::::::::: Submit Button :::::::::: */}
                </form>
              </Collapse>
            </div>
          </div>
        </div>
      </TbFilterLayout>
    </Fragment>
  );
};

export default React.memo(TbFilter);

const TbFilterLayout = styled.div`
  &.default-according.style-1 button {
    //width: auto;
  }
`;
