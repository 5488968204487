import React, { Fragment, useEffect, useState } from 'react';
import TbFilter from '../../../components/common/ui/TbFilter';
import DataTable from 'react-data-table-component';
import useApiError from '../../../hooks/useApiError';
import { ReqWorkflowList } from '../../../api/workflowAPI';
import { useQuery } from 'react-query';
import { logoquestColumns } from './data/logoquest-columns';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Col, Row } from 'reactstrap';
import TbSelect from '../../../components/common/ui/form/TbSelect';
import { formatEndDate, formatStartDate } from '../../../utils/formatUtils';
import { cellsStyle, headCellsStyle, rowsStyle } from '../../../data/customizer/react-data-table-config';
import { getLogoQuestPage } from '../../../api/logoquest';

const statusList: any[] = [
  { label: '신규 문의', value: 'REQUEST' },
  { label: '상담중', value: 'PROCEEDING' },
  { label: '상담 완료', value: 'COMPLETED' },
  { label: '고객 전환', value: 'CONVERSION' },
];

const LogoQuestList = () => {
  const handleApiError = useApiError(); // 에러 처리 훅

  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('query');
  const status = queryParams.get('status');

  const navigate = useNavigate();
  const [pageInfoData, setPageInfoData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  //  state
  const [reqParam, setReqParam] = useState<ReqWorkflowList>({
    query: '',
    startDate: '',
    endDate: '',
    page: 0,
    size: 30,
    sort: '',
    // custom
    type: 'GENERAL',
    onBoarding: '',
    manager: '',
    design: '',
    platform: '',
    priority: '',
  });

  const { isLoading } = useQuery(['getLogoQuestPage', { reqParam }], () => getLogoQuestPage(reqParam), {
    onSuccess: (data) => {
      setPageInfoData(data?._embedded?.brandQuestPageResBodies || []);
      setTotalRows(data?.page?.totalElements || 0);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  // 페이지 변경 핸들러
  const handlePageChange = (page: number) => {
    setReqParam({
      ...reqParam,
      page: page - 1,
    });
  };

  // 한 페이지 당 보여줄 행 수 변경 핸들러
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParam({
      ...reqParam,
      size: newPerPage,
      page: page - 1,
    });
  };

  // =================== 공통 항목 끝 ==================  //

  // 검색 핸들러
  const onSubmitSearch = (filters: any) => {
    setReqParam({
      ...reqParam,
      query: filters.query,
      startDate: filters.startDate ? formatStartDate(filters.startDate) : null,
      endDate: filters.endDate ? formatEndDate(filters.endDate) : null,
      type: filters.type,
      onBoarding: filters.onBoarding,
      design: filters.design,
      platform: filters.platform,
      manager: filters.manager,
      priority: filters.priority,
    });
  };

  // 행 클릭 핸들러
  const handleRowClicked = (row: any) => {
    navigate(`/admin/logoquest/update/${row.id}`);
  };

  return (
    <Fragment>
      <Breadcrumb parent="문의 내역 관리" title="로고 의뢰 내역 관리" />
      <div className="container-fluid">
        <div className="row">
          {/* :::::::::: Filter : Start ::::::::::  */}
          <div className="col-xl-12">
            <TbFilter onSubmit={onSubmitSearch}>
              {({ register, errors, setValue }) => {
                useEffect(() => {
                  setValue('query', query);
                  setValue('status', status);
                });
                return (
                  <Row>
                    <Col sm={6}>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">처리 상태</label>
                        </Col>
                        <Col sm={10}>
                          <TbSelect
                            placeholder={'전체'}
                            name="status"
                            options={statusList ? statusList : []}
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              }}
            </TbFilter>
          </div>
          {/* :::::::::: Filter : End ::::::::::  */}

          {/*  :::::::::: List ::::::::::  */}
          <div className=" col-xl-12 mb-4">
            <div className={'card-table-body'}>
              <DataTable
                noHeader
                columns={logoquestColumns}
                data={pageInfoData}
                progressPending={isLoading}
                highlightOnHover
                pointerOnHover
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationPerPage={30}
                paginationRowsPerPageOptions={[5, 10, 30, 50, 100]}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onRowClicked={handleRowClicked}
                dense={true}
                customStyles={{
                  rows: rowsStyle,
                  headCells: headCellsStyle,
                  cells: cellsStyle,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LogoQuestList;
