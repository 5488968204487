import APIClient from './APIClient';
import { PageInfo } from './types';

export interface ReqPaymentList extends PageInfo {
  pid?: string;
  orderStatus?: 'INIT' | 'PAID' | 'FAILED' | 'REFUND';
  target?: 'SUBSCRIBE' | 'CREDIT' | 'LOGO' | 'UNIVERSAL';
  idSubscription?: number;
}

export interface ReqCratePayUrl {
  amount: number | null; // 결제금액
  title: string | null; // 제품명
  buyerName?: string | null; // 주문자명 (optional)
  buyerTel: string | null; // 주문자연락처
  buyerEmail?: string | null; // 주문자 이메일주소 (optional)
  expiredAt: string | null; // 페이지 만료시각
}

export const getPaymentList = async (params: ReqPaymentList) => {
  const response = await APIClient.get(`/v2/api/payment/page`, {
    params,
  });
  return response.data;
};

export const getPayment = async (id: string) => {
  const response = await APIClient.get(`/v2/api/payment/${id}`);
  return response.data;
};

export const syncPayment = async (id: string) => {
  const response = await APIClient.patch(`/v2/api/payment/${id}/sync`);
  return response.data;
};

export const deletePayment = async (id: string) => {
  const response = await APIClient.delete(`/v2/api/payment/${id}`);
  return response.data;
};

export const createPayUrl = async (reqBody: ReqCratePayUrl) => {
  const response = await APIClient.post(`/v2/api/payment/url`, reqBody);
  return response.data;
};
