import React from 'react';

import Error400 from '../pages/errors/error400';
import Error401 from '../pages/errors/error401';
import Error403 from '../pages/errors/error403';
import Error404 from '../pages/errors/error404';
import Error500 from '../pages/errors/error500';
import Error503 from '../pages/errors/error503';
import Logins from '../pages/login';

// 인증관려 페이지(로그인 없이 접속 가능)
export const authRoutes = [
  { path: `/admin/login`, Component: <Logins /> },
  // Error Page
  { path: `/admin/errors/error400`, Component: <Error400 /> },
  { path: `/admin/errors/error401`, Component: <Error401 /> },
  { path: `/admin/errors/error403`, Component: <Error403 /> },
  { path: `/admin/errors/error404`, Component: <Error404 /> },
  { path: `/admin/errors/error500`, Component: <Error500 /> },
  { path: `/admin/errors/error503`, Component: <Error503 /> },
];
