import APIClient from './APIClient';
import { PageInfo } from './types';

export interface ReqCategoryIndustry {
  id: string;
  category: string;
}

// 산업군_카테고리
// 리스트
export const getCategoryIndustryList = async () => {
  const response = await APIClient.get(`/v2/api/industry/category/list`, {});
  return response.data;
};
// 상세
export const getCategoryIndustry = async (id: string) => {
  const response = await APIClient.get(`/v2/api/industry/category/${id}`, {});
  return response.data;
};
// Create
export const postCategoryIndustry = async (data: any) => {
  const response = await APIClient.post(`/v2/api/industry/category`, data);
  return response.data;
};
// Delete
export const deleteCategoryIndustry = async (id: string) => {
  const response = await APIClient.delete(`/v2/api/industry/category/${id}`, {});
  return response.data;
};
// Update
export const patchCategoryIndustry = async (id: string, data: any) => {
  const response = await APIClient.patch(`/v2/api/industry/category/${id}`, data);
  return response.data;
};

// 산업군
// 리스트
export const getIndustryList = async (params: PageInfo) => {
  const response = await APIClient.get(`/v2/api/industry/page`, {
    params,
  });
  return response.data;
};
// 상세
export const getIndustry = async (id: string) => {
  const response = await APIClient.get(`/v2/api/industry/${id}`, {});
  return response.data;
};
// Crate
export const postIndustryList = async (data: any) => {
  const response = await APIClient.post(`/v2/api/industry`, data);
  return response.data;
};
// Delete
export const deleteIndustry = async (id: string) => {
  const response = await APIClient.delete(`/v2/api/industry/${id}`, {});
  return response.data;
};
// Patch
export const patchIndustry = async (id: string, data: any) => {
  const response = await APIClient.patch(`/v2/api/industry/${id}`, data);
  return response.data;
};
