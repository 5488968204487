import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from '../store/reducers/modalSlice';
import { toast } from 'react-toastify';

const useApiError = () => {
  const dispatch = useDispatch();

  /* 일반적인 API 요청에 대한 Error 처리 */
  return useCallback((error: any) => {
    if (error?.response) {
      if (error.response.status >= 400 && error.response.status < 500) {
        // 400번대 에러
        toast.warn(
          <div>
            <b>{error.response.status} Error</b>
            <br />
            {error.response.data.message ? error.response.data.message : '잘못된 접근입니다.'}
          </div>,
        );
      } else if (error.message) {
        // 런타임 에러
        dispatch(
          openModal({
            showHeader: true,
            showFooter: true,
            headerTitle: `Runtime Error`,
            message: error.response.data.message
              ? error.response.data.message
              : 'Runtime Error : 관리자에게 문의하세요.',
          }),
        );
      } else {
        // 그 외 서버 에러
        dispatch(
          openModal({
            showHeader: true,
            showFooter: true,
            headerTitle: `${error.response.status} Error`,
            message: error.response.data.message
              ? error.response.data.message
              : 'Server Error : 관리자에게 문의하세요.',
          }),
        );
      }
    }
  }, []);
};

export default useApiError;
