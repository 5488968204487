// 메뉴

//
export const logoquestColumns = [
  {
    name: 'ID',
    selector: (row: any) => row.id,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  },
  {
    name: '담장자 성함',
    selector: (row: any) => row.userName,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '200px',
  },
  {
    name: '이메일',
    selector: (row: any) => row.email,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '200px',
  },
  {
    name: '브랜드명',
    selector: (row: any) => row.name,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '200px',
  },
  {
    name: '번호',
    selector: (row: any) => row.phoneNumber,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '200px',
  },
];
